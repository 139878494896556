import React, { Component } from 'react'
import '../styles/domain_available.css'
import Register from './register'


export class Domain_available extends Component {
  getNScall =() =>{
    this.props.getNS();
    // console.log('do getNScall');
  }
  
  render() {
    // let name = 'william.stark'
    // console.log(this.props.dsname);
    return (
        <div>
            <ul className='domain_find_info'>
                <p>This domain is available!</p>
            </ul> 
            <ul className='domain_find_info_input'>
                 <ul className='domain_find_info_input_child'>
                    {/* <li>{this.props.name + '.stark'}</li> */}
                    <li>{this.props.resultName}</li>
                    <li>Available</li>
                  </ul>
            </ul>
            {this.props.dsname == 0 && <Register basePrice ={this.props.basePrice} name = {this.props.name} getNScall={this.getNScall}/>}
        </div>
    
    )
  }
}

export default Domain_available