const erc20Abi = [
    {
      "name": "constructor",
      "type": "function",
      "inputs": [
        {
          "name": "name",
          "type": "core::felt252"
        },
        {
          "name": "symbol",
          "type": "core::felt252"
        },
        {
          "name": "_owner",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "name",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::felt252"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "symbol",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::felt252"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "decimals",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::integer::u8"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "total_supply",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::integer::u256"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "balanceOf",
      "type": "function",
      "inputs": [
        {
          "name": "account",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [
        {
          "type": "core::integer::u256"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "allowance",
      "type": "function",
      "inputs": [
        {
          "name": "owner",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "spender",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [
        {
          "type": "core::integer::u256"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "get_mint_amount",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::integer::u256"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "get_pool_amount",
      "type": "function",
      "inputs": [],
      "outputs": [
        {
          "type": "core::integer::u256"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "mint",
      "type": "function",
      "inputs": [
        {
          "name": "recipient",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "poolMint",
      "type": "function",
      "inputs": [
        {
          "name": "recipient",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "set_friend",
      "type": "function",
      "inputs": [
        {
          "name": "_mint_friend",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "_pool_mint_friend",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "transfer",
      "type": "function",
      "inputs": [
        {
          "name": "recipient",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "transferFrom",
      "type": "function",
      "inputs": [
        {
          "name": "sender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "recipient",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "transfer_from",
      "type": "function",
      "inputs": [
        {
          "name": "sender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "recipient",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "approve",
      "type": "function",
      "inputs": [
        {
          "name": "spender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "amount",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "increaseAllowance",
      "type": "function",
      "inputs": [
        {
          "name": "spender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "added_value",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "decreaseAllowance",
      "type": "function",
      "inputs": [
        {
          "name": "spender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "subtracted_value",
          "type": "core::integer::u256"
        }
      ],
      "outputs": [
        {
          "type": "core::bool"
        }
      ],
      "stateMutability": "external"
    },
    {
      "name": "Transfer",
      "type": "event",
      "inputs": [
        {
          "name": "from",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "to",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "value",
          "type": "core::integer::u256"
        }
      ]
    },
    {
      "name": "Approval",
      "type": "event",
      "inputs": [
        {
          "name": "owner",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "spender",
          "type": "core::starknet::contract_address::ContractAddress"
        },
        {
          "name": "value",
          "type": "core::integer::u256"
        }
      ]
    }
  ]

  export default erc20Abi