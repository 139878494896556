import React, { useState, useRef, useContext, useEffect  } from 'react'
import '../styles/community_leader.css'
import Maincontext from './context'
import axios from 'axios'



function HandString(str){
    let length = str.length;
    let pre;
    if(str.length == 64){
        pre = str.substr(0,2) + '00' + str.substr(2,1);

    }else{
        pre = str.substr(0,5);
    }
    // let pre = str.substr(0,5);
    let end = str.substr(length - 4, 4);
    return pre+'...'+ end;
}

const ParsTime=(time)=>{
      let timestamp = time
      if(time == 0){
            return 0
      }else{
            let date = new Date(parseInt(timestamp) * 1000);
            let Year = date.getFullYear();
            let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
            let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
            let  GMT =  Year + '-' + Moth + '-' + Day;
            return (GMT + ' GMT')
}
}

function GetLeaderInfo(){
      
        const leaderRef = useRef(); //创建useRef
        const [page, setPage] = useState(1);
        const [totalPage, setTotalPage] = useState(1);
        const [data, setData] = useState([]);
        const myAccount = useContext(Maincontext);

        const getData = async(_pages, _address) =>{
            let chains_num = 1
            if(myAccount.network == 'zkSync Era'){
              chains_num = 2
            }else if(myAccount.network == 'Polygon zkEVM'){
              chains_num = 3
            }else if(myAccount.network == 'Linea'){
              chains_num = 4
            }
            try{
              const res = await axios.get('https://zk-ns.xyz/api/getMyMember', {
                  params: {
                    chain_num: chains_num,
                    page_num: _pages,
                    page_size: 10,
                    address: _address
                  }
              })
              if(res.status == 200){
                if(res.data.paging.total%res.data.paging.page_size == 0){
                  if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                    setTotalPage(res.data.paging.total/res.data.paging.page_size)
                  }
                }else{
                    setTotalPage(res.data.paging.total/res.data.paging.page_size + 1)
                }
                let arr = []
                for(let i = 0; i < res.data.data.length; i++){
                  arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].member_address), time: ParsTime(res.data.data[i].time)})
                }
                setData(data.concat(arr))
               }
            }catch(e){
              console.log(e)
            }
          }

        const handleScroll = () =>{
            let res=leaderRef.current.scrollHeight - leaderRef.current.clientHeight- leaderRef.current.scrollTop;
            // console.log(couterRef.current.scrollTop);//一直增加
            // console.log(couterRef.current.clientHeight); //300
            // console.log(couterRef.current.scrollHeight); //929
            if (res>1) {
                  //未到底
              } else {
                  //已到底部
                  // console.log('到底了')
                  if(page < totalPage){
                        if(myAccount.address.length>0){
                          getData(page+1, myAccount.address)
                          setPage(page+1)
                        }
                  }
            }
          }

          useEffect ( ()=>{
            if(myAccount.address.length>0){
                  getData(page, myAccount.address)
            }
          }, [myAccount.address]);
          if(data.length> 0){
                  return (
                  <div className='leader_info_content' ref={leaderRef} onScroll={handleScroll}>
                        {data.map(item => <div className='leader_info_content_detail' key={item.id}>
                              <li>{item.name}</li>
                              <li>{item.time}</li>
                        </div>)}
                        
                        </div>
                  )
          }else{
            return (
                  <div className='leader_no_info' >
                      No Community Members Yet
                    </div>
                )
          }
}

function GetLeaderEarnInfo(){
//   let name = '0x23...56wd'
//   let time = new Date().toLocaleDateString()
//   let amount = 0.005
      const earnRef = useRef(); //创建useRef
      const [page, setPage] = useState(1);
      const [totalPage, setTotalPage] = useState(1);
      const [data, setData] = useState([]);
      const myAccount = useContext(Maincontext);

      const getData = async(_pages, _address) =>{
            let chains_num = 1
            if(myAccount.network == 'zkSync Era'){
              chains_num = 2
            }else if(myAccount.network == 'Polygon zkEVM'){
              chains_num = 3
            }else if(myAccount.network == 'Linea'){
              chains_num = 4
            }
            try{
              const res = await axios.get('https://zk-ns.xyz/api/getMyCommunityEarn', {
                  params: {
                    chain_num: chains_num,
                    page_num: _pages,
                    page_size: 10,
                    address: _address
                  }
              })
              if(res.status == 200){
                if(res.data.paging.total%res.data.paging.page_size == 0){
                  if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                    setTotalPage(res.data.paging.total/res.data.paging.page_size)
                  }
                }else{
                    setTotalPage(res.data.paging.total/res.data.paging.page_size + 1)
                }
                let arr = []
                for(let i = 0; i < res.data.data.length; i++){
                  let kind;
                  if(res.data.data[i].kind == 1){
                      kind = 'NGT'
                      arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].from_address), amount: (res.data.data[i].amount/(10**18)).toFixed(1), kind: kind, time: ParsTime(res.data.data[i].time)})
                  }else{
                      kind = 'ETH'
                      arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].from_address), amount: (res.data.data[i].amount/(10**18)).toFixed(3), kind: kind, time: ParsTime(res.data.data[i].time)})
                  }
                  
                }
                setData(data.concat(arr))
               }
            }catch(e){
              console.log(e)
            }
      }
      
      const handleScroll = () =>{
            let res=earnRef.current.scrollHeight - earnRef.current.clientHeight- earnRef.current.scrollTop;
            if (res>1) {
                  } else {
                  if(page < totalPage){
                        if(myAccount.address.length>0){
                              getData(page+1, myAccount.address)
                              setPage(page+1)
                        }
                  }
            }
      }

      useEffect ( ()=>{
        if(myAccount.address.length>0){
            getData(page, myAccount.address)
        }
      }, [myAccount.address]);
      if(data.length> 0){
            return (
            <div className='leader_info_content' ref={earnRef} onScroll={handleScroll}>
                  {data.map(item=><div className='leader_earn_detail' key={item.id}>
                        <li>{item.name}</li>
                        <li>{item.amount}</li>
                        <li>{item.kind}</li>
                        <ul>{item.time}</ul>
                  </div>)}
                  
            </div>
            )
      }else{
            return (
                  <div className='leader_no_info' >
                      No Community Earnings Yet
                    </div>
                )
      }
}


const Community_leader = () => {
    
    const [choose, setChoose] = useState('invite')
    
    const handerClick = (data) => {
      setChoose(data)
      // console.log(data)
    }
    
    return (
            <div>
               
                <div className='leader_info'>
                    <div className='leader_info_title'>
                      <div className='title_wrap' onClick={()=>handerClick('invite')}>
                        <li className= {choose === 'invite' ? "chose" : ""}>Community Members</li>
                        </div>
                      <div className='title_wrap' onClick={()=>handerClick('earn')}>
                        <li className={choose === 'earn' ? "chose" : ""} >Community Earnings</li> 
                        </div>
                    </div>
                 </div>
                <div className='leader_info' >
                      {choose === 'invite' ? <GetLeaderInfo /> : <GetLeaderEarnInfo />}
                </div>
                
            </div>
        
        )
}

export default Community_leader