import * as ethers from "ethers";
import nsAbi from "../json/polygonzkevm_ns_json"
import erc20Abi from "../json/polygonzkevm_erc20_json"
import stakeAbi from "../json/polygonzkevm_stake_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    
    providers = new ethers.providers.JsonRpcProvider('https://rpc.linea.build')
    
    // providers = new ethers.providers.JsonRpcProvider('https://linea-mainnet.infura.io/v3/')
    //testnet
    // testContractAddress = "0x4c8b1e1f6636002c4940e8409f2641cba18c633c"
    testContractAddress = "0xa8cccb1be30604e69d5014022b56aed8b247bfb0"
    testerc20Address = "0x3971797B7178d0f782E0b5477d53af922ebeDFea"
    teststakecontractAddress = "0xb2b8578c1d7bc82875721a5346cce565bc4aa455"

    // provider = new ethers.providers.Web3Provider(
    //     window.ethereum,
    // )
    // signer = this.provider.getSigner()

    chainID = 183

    LineaContractInit = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    LineaContract = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    LineaErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.providers)
    LineaStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.providers)
    
    constructor(){
        makeAutoObservable(this)
    }
    reset() {
        this.provider = new ethers.providers.Web3Provider(
            window.ethereum,
        )
        this.signer = this.provider.getSigner()
        this.LineaContract = new ethers.Contract(this.testContractAddress, nsAbi, this.signer)
        this.LineaErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.signer)
        this.LineaStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.signer)
      }

}
const LineaWallet = new Wallet()

export {LineaWallet}