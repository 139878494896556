import React, { useState, useContext, useEffect } from 'react';
import { Contract, Account, ec, json, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import '../styles/game.css';
// import Cube from './cube';
import Egg from './egg';
import Chick from './chick';
import More from '../icons/more.png'
import Up from '../icons/up.png'
import plus from '../icons/plus.png'
import reduce from '../icons/reduce.png'
import hot_logo from '../icons/hot.png'
import eth_logo from '../icons/ETH.png'
import loading from '../icons/loading.gif'
import Maincontext from './context'
import { StarknetWallet } from '../store/starknet'
import { set } from 'mobx';


const Game = ({setListdata, disConnect, connectAccount, listData}) => {

  const [mores, setMores] = useState(false);
  const [num, setNum] = useState(1);
  const myAccount = useContext(Maincontext);
  const [eggPrice, setEggPrice] = useState(0);
  const [checkName, setCheckName] = useState('Connect Wallet');
  const [checkStatus, setCheckStatus] = useState(0);
  const [chickMintStatus, setChickMintStatus] = useState(0);
  const [eggMintStatus, setEggMintStatus] = useState(0);
  const [freeMintName, setFreeMintName] = useState('Connect Wallet');
  const [mintName, setMintName] = useState('Connect Wallet');
  const [chickMintNum, setChickMintNum] = useState(0);
  const [eggMintNum, setEggMintNum] = useState(0);
  // const [myMintChick, setMyMintChick] = useState(false);
  const [myMintEgg, setMyMintEgg] = useState(0);

 
  const expire = 1703246400000;
  // const expire = 1701185502000;
     
  const countDown = () => {
    const nowTime = +new Date(); //获取當前时间的时间戳（单位毫秒）
    const times = parseInt(`${(expire - nowTime) / 1000}`); //把剩余时间毫秒数转化为秒
    let d = parseInt(`${(times / 60 / 60 ) / 24}`); //计算天数 转化为整数
    let h = parseInt(`${(times / 60 / 60) % 24}`); //计算小时数 转化为整数
    let m = parseInt(`${(times / 60) % 60}`); //计算分钟数 转化为整数
    let s = parseInt(`${times % 60}`); //计算描述 转化为整数
  
    // console.log('d:', d, 'h:', h, 'm:', m, 's:', s) 
    if(d < 10){
      d = `0${d}`
    }
    if(h < 10){
      h = `0${h}`
    }
    if(m < 10){
      m = `0${m}`
    }
    if(s < 10){
      s = `0${s}`
    }
    let times_ = d + "d : " + h + "h : " + m + "m : " + s + "s";
    if(times > 0){
      setFreeMintName(times_)
      setMintName(times_)
      setTimeout(() => {
        countDown();
      }, 1000);//推迟1秒后重新调用自己
    }else{
      setFreeMintName('Free Mint')
      setMintName('Mint')
      // console.log('still do')
    }
    
    
  }


  const doMore = () =>{
    setMores(true);
  }
  const doUp = () =>{
    setMores(false);
  }

  const plusing = () =>{
    if(num < 10){
      setNum(num + 1);
    }
  }
  const reducing = () =>{
    if(num > 1){
      setNum(num - 1);
    }
  }
  const checkEligible = async() =>{
    if(myAccount.address.length > 0){
        //do check
        if(checkName == 'Check Eligible'){
          setCheckStatus(1);
          // const res1 = await StarknetWallet.StarknetContract.get_ns('0x07297c0fDD18E1Ba792Bca1110c82ab57Efcb90bCc76B8B0492F25148180b85d');
          const res1 = await StarknetWallet.StarknetContract.get_ns(myAccount.address);
          // const myTextDecoded = shortString.decodeShortString(res1);
          // console.log(res1.toString());
          if(res1.toString() > 0){
            setCheckName("You're eligible!")
          }else{
            setCheckName("You're not eligible!")
          }
          setCheckStatus(0);
        }
    }else{
        connectAccount()
    }
  }
  const freeMint = async() =>{
    if(freeMintName == 'Free Mint'){
      if(myAccount.address.length > 0){
        //do free mint
        if(checkName == "You're eligible!" || checkName == "Check Eligible"){
          // console.log('do free mint');
          setChickMintStatus(1)
          try{
              const mintCall = await myAccount.account.execute(
                [
                // Calling the first contract
                {
                  contractAddress: StarknetWallet.freeMintContractAddress,
                  entrypoint: "free_mint_chick",
                  // approve 1 wei for bridge
                  calldata: CallData.compile({
                      // recommend: recommendAddress,
                  })
                }
                ]
              )
              await StarknetWallet.provider.waitForTransaction(mintCall.transaction_hash);
              setFreeMintName('Already Minted')
          }catch(e){
            console.log(e)
          }
          setChickMintStatus(0)
        }
      }else{
        connectAccount()
      }
    }
    
  }

  const mintEgg = async() =>{
    if(mintName == 'Mint' || mintName == 'Connect Wallet'){
      if(myAccount.address.length > 0){
          const res1 = await StarknetWallet.StarknetFreeMintContract.get_mint_info(myAccount.address);
          if(parseInt(res1.my_mint_egg.toString()) + num > 10){
            alert('Exceeding quantity limit');
          }else{
              setEggMintStatus(1)
              //10000000000000, 0.00001
              try{
                  const price = uint256.bnToUint256(1000000000000000 * num);
                  const amount = uint256.bnToUint256(num);
                  const multiCall = await myAccount.account.execute(
                      [
                        // Calling the first contract
                        {
                          contractAddress: StarknetWallet.testETHAddress,
                          entrypoint: "approve",
                          // approve 1 wei for bridge
                          calldata: CallData.compile({
                              spender: StarknetWallet.freeMintContractAddress,
                              amount: price,
                          })
                        },
                        // Calling the second contract
                        {
                          contractAddress: StarknetWallet.freeMintContractAddress,
                          entrypoint: "free_mint_egg",
                          calldata: CallData.compile({
                              amount: amount,
                              version: 0
                          })
                        }
                    ]
                    )
                  await StarknetWallet.provider.waitForTransaction(multiCall.transaction_hash);
                  if(res1.my_mint_egg.toString() + num >= 10){
                      setMintName('Already Minted')
                  }
                  setMyMintEgg(parseInt(res1.my_mint_egg.toString()) + num);
                  
              }catch(e){
                console.log(e)
              }
              setEggMintStatus(0)
              // setMintName('Already Minted')
          }
      }else{
        connectAccount()
      }
    }

  }

  const getInitInfo = async() =>{
    const nowTime = +new Date();
    if( nowTime > expire){
      if(myAccount.address.length > 0){
        const res1 = await StarknetWallet.StarknetFreeMintContract.get_mint_info(myAccount.address);
        // console.log(res1)
        setChickMintNum(res1.chick_mint_num.toString())
        setEggMintNum(res1.egg_mint_num.toString())
        // setMyMintChick(res1.my_mint_chick)
        setMyMintEgg(res1.my_mint_egg.toString())
        if(res1.my_mint_chick){
          setFreeMintName('Already Minted')
        }
        if(res1.my_mint_egg.toString() >= 10){
          setMintName('Already Minted')
        }
        if(res1.chick_mint_num.toString() >= 1000){
          setFreeMintName('Minted Out')
        }
        if(res1.egg_mint_num.toString() >= 100000){
          setMintName('Minted Out')
        }
      }

    }

  }

  useEffect (()=>{
    countDown();
    getInitInfo();
    if(listData != 'Starknet'){
      setListdata('Starknet')
      disConnect()
    }
    if(myAccount.address.length > 0){
      setEggPrice(0.001);
      setCheckName('Check Eligible');
      // setFreeMintName('Free Mint');
      setMintName('Mint');
    }else{
      setEggPrice(0);
      setCheckName('Connect Wallet');
      // setFreeMintName('Connect Wallet');
      setMintName('Connect Wallet');
    }
  }, [myAccount.address, myAccount.network]);

  return (
    <div className='game_parent'>
      <div className='game_parent_item'>
        <div className='game_info'>
          <div className='launch_app'>
              <li ><a href='https://game.zk-ns.xyz' target="_blank">Launch App</a></li>
          </div>
          <li className='game_title'>Game<img src={hot_logo}/><li className='game_title_tip'>(only supports Starknet network)</li></li>
          <li className='game_content'>We have been thinking about what kind of games can be called web3 games? What kind of web3 games can be popularized on a large scale?
              The first question is that there are some obvious characteristics that can be verified, such as NFT, and the logic and data of the game need to be completed on the chain.
              The second question is obviously difficult to answer.</li>
            <li className='game_content_more' style={{ display: mores ? "none": "flex" }} onClick={doMore}>More<img src={More} /></li>
            <li className='game_content_detail' style={{ display: mores ? "block": "none" }}>Let's take a look at some of the features that classic web3 games have. 
            
            <br/>For example, CryptoKitties, its typical feature is pet NFT, which can be bought and sold very easily (convenient for large-scale popularization), can be reproduced (users can generate income) to produce more NFT, and the gameplay logic and data are all in the smart contract. Another example is Axie Infinity. Its typical feature is pet NFT, which can also be reproduced to produce more NFT, while adding more complex gameplay benefits.
          
            <br/>It can be seen that in order to design a web3 game that can be popularized on a large scale, it needs to have several basic characteristics:
            <br/>1. Pet NFT is convenient for users to enter, purchase, adopt and cash out;
            <br/>2. It can be reproduced and benefit users;
            <br/>3. The logic and data are completely in the smart contract, and all data and rules are open and transparent.
            <br/>With reference to these characteristics and the advantages and disadvantages of other web3 games, we designed this web3 game.
            The game has two NFTs, one is the chicken NFT, which is an ERC721 standard NFT; the other is an egg NFT, which is an ERC1155 standard NFT.
            <br/>The initial total amount of chicken NFT is 10,000, which is free, decentralized (no investor allocation, no developer allocation), and fully allocated to community users (any user with the zk-ns.xyz domain name can mint one chicken NFT for free).
            The initial total amount of egg NFT is 100,000, and the price is 0.001 ETH for each. It is a value measurement for chicken NFT. Anyone can mint no more than 10 egg NFTs.
            <br/>There are 2 ways to play chicken NFT:
            <br/>1. Stable income gameplay. You can lay 16-19 eggs. After the eggs are laid, the chicken NFT is destroyed and the eggs can be converted into NFT for sale, with a value of approximately 0.016-0.019 ETH;
            <br/>2. To play the breeding income game, to hatch a chick, you need 10 egg NFTs and 1 chicken NFT to be put into the game together (10 egg NFTs and 1 chicken NFT are destroyed). After the incubation process, 3-4 chicken NFTs can be hatched.
            <br/>Some interesting gameplay methods are also added to the game, such as random numbers of luck, chickens eating bugs, community users being able to steal eggs, etc.
            <br/>The steady income gameplay will lead to a decrease in the number of chicken and an increase in the number of eggs. The breeding income gameplay will lead to a decrease in eggs and an increase in the number of chicken. The quantity and price of the two will reach a dynamic balance with the market conditions. The specific gameplay and economy model can be seen in the announcement content of our discord channel. &ensp;
            <img className='game_content_up' style={{ display: mores ? "": "none" }} onClick={doUp} src={Up} />
          </li>
        </div>
        <div className='game_item'>
          <div className='game_child'>
            <div className='game_left'><Chick/></div>
            <div className='game_info_chick'>
              <div className='chick_check'>
              {checkStatus == 0 ? <li onClick={checkEligible} style={{ color: checkName == "You're eligible!" ? "#42f2ae" : (checkName == "You're not eligible!" ? "red": "#fff") }}>{checkName}</li> : <li><img src={loading} /></li> }
                
                {/* <li>You're not eligible</li> */}
              </div>
                <div className='chick_mint_title'>
                  <li className='chick_mint_title_left'>zk-Chicken Mint</li>
                  <li className='chick_mint_title_right'>{chickMintNum} / 1000</li>
                </div>
                <div className='chick_mint_price'>
                  <div className='chick_mint_price_left'>
                        <img src={reduce} ></img>
                        <li className='chick_mint_price_num'>1</li>
                        <img src={plus} ></img>
                  </div>
                  <li className='chick_mint_price_right'>Price: <img src={eth_logo}/> 0</li>
                </div>
                <div className='chick_mint_do'>
                {chickMintStatus == 0 ?  <li style={{ pointerEvents: freeMintName == 'Free Mint' ? "" : "none", color: (freeMintName == 'Already Minted' || freeMintName == 'Minted Out') ? "rgb(173, 173, 173)" : "#42f2ae"}} onClick={freeMint}>{freeMintName}</li> : <li><img src={loading} /></li>}
                </div>
            </div>
          </div>
          <div className='game_child'>
            <div className='game_right'><Egg/></div>
            <div className='game_info_egg'>
                  <div className='egg_mint_title'>
                    <li className='egg_mint_title_left'>zk-Egg Mint</li>
                    <li className='egg_mint_title_right'>{eggMintNum} / 100000</li>
                  </div>
                  <div className='egg_mint_price'>
                    <div className='egg_mint_price_left'>
                          <img src={reduce} onClick={reducing} ></img>
                          <li className='egg_mint_price_num'>{num}</li>
                          <img src={plus} onClick={plusing}></img>
                    </div>
                    <li className='egg_mint_price_right'>Price: <img src={eth_logo}/> {parseInt(eggPrice * num *1000)/1000}</li>
                  </div>
                  <div className='egg_mint_num'>
                    <li>My zk-Egg nft: &emsp;  {myMintEgg} / 10</li>
                  </div>
                  <div className='egg_mint_do'>
                  {eggMintStatus == 0 ?  <li style={{ pointerEvents: (mintName == 'Mint' || mintName == 'Connect Wallet') ? "" : "none", color: (mintName == 'Already Minted' || mintName == 'Minted Out') ? "rgb(173, 173, 173)" : "#42f2ae"}} onClick={mintEgg}>{mintName}</li> : <li><img src={loading} /></li>}
                    {/* <li>{mintName}</li> */}
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Game