import React, { useState, useEffect }  from 'react'
import { Contract, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import Mydomain from "./mydomain"
import Community from "./community"
import Game from './game';
import NGT from '../icons/NGT.png'
import discords from '../icons/discord.png'
import twitters from '../icons/twitter-1.png'
import starknet_logo from '../icons/starknet.png'
import zkSync_Era_logo from '../icons/zkSync-Era.png'
import Polygon_zkEVM_logo from '../icons/Polygon-zkEVM.png'
import scroll_logo from '../icons/scroll.png'
import Linea_logo from '../icons/Linea.png'
import Celo_logo from '../icons/celo.png'
import CoreDao_logo from '../icons/coredao.png'
import Klaytn_logo from '../icons/klaytn.png'
import Gnosis_logo from '../icons/gnosis.png'
import Bevm_logo from '../icons/bevm50.png'
import layerzero_logo from '../icons/layerzero.png'
import hot_logo from '../icons/hot.png'
import select_down from '../icons/select_down.png'
import mobile_nav from '../icons/mobile_nav.png'
import { ConnectOptions, DisconnectOptions, connect, disconnect } from "get-starknet"
import Maincontext from './context'
import * as ethers from "ethers"
import {ZKsyncWallet } from '../store/zksync';
import {PolygonZKevmWallet } from '../store/polygonzkevm';
import {PolygonWallet } from '../store/polygon';
import {CeloWallet } from '../store/celo';
import {KlaytnWallet } from '../store/klaytn';
import {CoreDaoWallet } from '../store/coredao';
import {GnosisWallet } from '../store/gnosis';
import {LineaWallet } from '../store/linea';
import {BevmWallet} from '../store/bevm'
import Bridge from './bridge';
import { useNavigate } from 'react-router-dom';



const Header = ({ chooses, chain, accounts, setAccounts }) => {

    const isConnected = Boolean(accounts[0]);
    const [choose, setChoose] = useState(chooses);
    const [account, setAccount] = useState();
    const [listClicks, setListClicks] = useState(false)
    const [listData, setListdata] = useState(chain)
    const [disconnectStatus, setDisconnectStatus] = useState(false)
    const [names, setNames] = useState(accounts);
    let obj = {
      address: accounts,
      account: account,
      network: listData
    }
    const navigate = useNavigate()

    const displayList = () => {
      if(choose == 'game'){//only support starknet
      }else{
        setListClicks(!listClicks)
      }
    }
    const handerListClick = async(msg) =>{
      
      if(msg == listData){
        // console.log('same, not do')
      }else{
        if(accounts.length > 0){
          await disConnect();
          // console.log('do disconnect')
        }
      }
      setListdata(msg)
      setListClicks(!listClicks)

    }
    const getLogo=() =>{
      if(listData == 'Starknet'){
        return starknet_logo
      }else if(listData == 'zkSync Era'){
        return zkSync_Era_logo
      }else if(listData == 'Polygon zkEVM'){
        return Polygon_zkEVM_logo
      }else if(listData == 'Celo'){
        return Celo_logo
      }else if(listData == 'Polygon'){
        return Polygon_zkEVM_logo
      }else if(listData == 'Linea'){
        return Linea_logo
      }else if(listData == 'CoreDao'){
        return CoreDao_logo
      }else if(listData == 'Klaytn'){
        return Klaytn_logo
      }else if(listData == 'Bevm'){
        return Bevm_logo
      }else if(listData == 'Gnosis'){
        return Gnosis_logo
      // }else if(listData == 'Scroll Testnet'){
      //   return scroll_logo
      }

    }
    

    function handerClick(data) {
        setChoose(data);
        if(data == 'domain'){
          navigate('/');
        }else if(data == 'community'){
          navigate('/community');
        }else if(data == 'game'){
          navigate('/game');
        }else if(data == 'bridge'){
          navigate('/bridge');
        }
    }

    // async function get_zkns(addr){
    //   if(listData == 'Starknet'){
    //     const resu3 = await StarknetWallet.StarknetContract.getZKns(ns);
    //   }
    //   const resu = await BevmWallet.BevmContractInit.getZKns(addr);
    //   setNames(resu.toString());

    // }

    function HandString(){
      if(names.length >12){
        let str = names;
        // console.log(names)
        let pre = str.substr(0,6);
        let end = str.substr(str.length - 4, 4);
        return pre+'...'+ end;
      }else{
        return names;
      }

    }

    

    async function connectAccount() {
        if(listData == 'Starknet'){
            try{
              const res = await connect({
                modalMode: "alwaysAsk",
                modalTheme: "dark",
              })
    
              if(res){
                if(encode.sanitizeHex(res.account.address).length == 64){
                  let addr = encode.sanitizeHex(res.account.address);
                  let result_addr = addr.substr(0,2) + '00' + addr.substr(2, 62);
                  setAccounts(result_addr);
                  setNames(result_addr);
                  // await get_zkns(result_addr);
                  
                }else{
                  setAccounts(encode.sanitizeHex(res.account.address));
                  setNames(encode.sanitizeHex(res.account.address));
                  // await get_zkns(encode.sanitizeHex(res.account.address));
                }
                setAccount(res.account);
                
              }else{
                console.log('not connected');
              }
    
            }catch(e){
              //  console.log(e)
              console.log('launch wallet error')
            }
        }else if(listData == 'Bevm' || listData == 'zkSync Era' || listData == 'Polygon zkEVM' || listData == 'Celo' || listData == 'Polygon' || listData == 'Linea' || listData == 'CoreDao' || listData == 'Klaytn' || listData == 'Gnosis'){
          if (window.ethereum) {
            try {
                  let accounts = await window.ethereum.request({
                    method: "eth_requestAccounts"
                  });
                  await checkNetwork();
                  setAccounts(accounts[0]);
                  setNames(accounts[0]);
                  // await get_zkns(accounts[0]);
                } catch (error) {
                  console.warn("Please authorize to access tour account");
                };
            // window.ethereum.on('accountsChanged', function (accounts) {
            // window.location.reload();
            // });
            // window.ethereum.on('chainChanged', function () {
            //   // window.location.reload();
            //   checkNetwork();
            // });
          } else {
            alert("Pls Install MetaMask!");
          };
        }
        
      }
    
      // async function handerSelectChange(e){
      //   setSelectValue(e.target.value);
      //   await disConnect();
      // }
      async function checkNetwork(){
            const provider = new ethers.providers.Web3Provider(
              window.ethereum,
            );
            let chain = await provider.getNetwork();
            // console.log(chain);
            // console.log(chain.chainId);
            // console.log(listData);
            if(listData == 'Bevm'){
              if(chain.chainId != 1501){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x5dd",
                      }
                    ]
                  });
                } catch (error) {
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x5dd",
                            chainName: 'BEVM',
                            rpcUrls: ['https://rpc-2.bevm.io'],
                            blockExplorerUrls: ['https://scan.bevm.io'],
                            nativeCurrency: {
                              name: 'BTC',
                              symbol: 'BTC',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Bevm manually");
                  }
                  // console.log(error);
                };
              }
              BevmWallet.reset()
            }else if(listData == 'zkSync Era'){
              if(chain.chainId != 324){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x144",
                      }
                    ]
                  });
                } catch (error) {
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x144",
                            chainName: 'zkSync Era',
                            rpcUrls: ['https://mainnet.era.zksync.io'],
                            blockExplorerUrls: ['https://explorer.zksync.io'],
                            nativeCurrency: {
                              name: 'ETH',
                              symbol: 'ETH',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add zkSync Era manually");
                  }
                  // console.log(error);
                };
              }
              ZKsyncWallet.reset()
            }else if(listData == 'Polygon zkEVM'){
              if(chain.chainId != 1101){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x44D"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x44D",
                            chainName: 'Polygon zkEVM',
                            rpcUrls: ['https://zkevm-rpc.com'],
                            blockExplorerUrls: ['https://zkevm.polygonscan.com'],
                            nativeCurrency: {
                              name: 'ETH',
                              symbol: 'ETH',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Polygon zkEVM manually");
                  }
                };
                
              }
              PolygonZKevmWallet.reset()
            }else if(listData == 'Linea'){
              if(chain.chainId != 59144){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0xE708"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0xE708",
                            chainName: 'Linea',
                            rpcUrls: ['https://rpc.linea.build'],
                            blockExplorerUrls: ['https://lineascan.build/'],
                            nativeCurrency: {
                              name: 'ETH',
                              symbol: 'ETH',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Linea manually");
                  }
                  
                };
                
              }
              LineaWallet.reset()
            }else if(listData == 'Celo'){
              if(chain.chainId != 42220){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0xA4EC"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0xA4EC",
                            chainName: 'Celo Mainnet',
                            rpcUrls: ['https://celo.api.onfinality.io/public'],
                            blockExplorerUrls: ['https://celoscan.io'],
                            nativeCurrency: {
                              name: 'Celo',
                              symbol: 'Celo',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Celo manually");
                  }
                  
                };
                
              }
              CeloWallet.reset()
            }else if(listData == 'Polygon'){
              if(chain.chainId != 137){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x89"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x89",
                            chainName: 'Polygon Mainnet',
                            rpcUrls: ['https://polygon-rpc.com'],
                            blockExplorerUrls: ['https://polygonscan.com'],
                            nativeCurrency: {
                              name: 'MATIC',
                              symbol: 'MATIC',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Polygon manually");
                  }
                  
                };
                
              }
              PolygonWallet.reset()
            }else if(listData == 'CoreDao'){
              if(chain.chainId != 1116){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x45C"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x45C",
                            chainName: 'CoreDao Mainnet',
                            rpcUrls: ['https://rpc.coredao.org'],
                            blockExplorerUrls: ['https://scan.coredao.org/'],
                            nativeCurrency: {
                              name: 'CORE',
                              symbol: 'CORE',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add CoreDao Mainnet manually");
                  }
                  
                };
                
              }
              CoreDaoWallet.reset()
            }else if(listData == 'Klaytn'){
              if(chain.chainId != 8217){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x2019"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x2019",
                            chainName: 'Klaytn Mainnet',
                            rpcUrls: ['https://public-node-api.klaytnapi.com/v1/cypress'],
                            blockExplorerUrls: ['https://scope.klaytn.com'],
                            nativeCurrency: {
                              name: 'KLAY',
                              symbol: 'KLAY',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Klaytn Mainnet manually");
                  }
                  
                };
                
              }
              KlaytnWallet.reset()
            }else if(listData == 'Gnosis'){
              if(chain.chainId != 100){
                try {
                  await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                      {
                      chainId: "0x64"
                      }
                    ]
                  });
                  
                } catch (error) {
                  // console.log(error);
                  try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                          {
                            chainId: "0x64",
                            chainName: 'Gnosis Mainnet',
                            rpcUrls: ['https://rpc.gnosischain.com'],
                            blockExplorerUrls: ['https://blockscout.com/xdai/mainnet/'],
                            nativeCurrency: {
                              name: 'xDAI',
                              symbol: 'xDAI',
                              decimals: 18
                            },
                          }
                        ]
                      })
                  } catch (ee) {
                    alert("Please add Gnosis Mainnet manually");
                  }
                  
                };
                
              }
              GnosisWallet.reset()
            }
            
      }
      function disConnectDisplay(){
        // console.log('launch disconnect')
        setDisconnectStatus(!disconnectStatus)
      }
      async function disconnectwallet(){
        setDisconnectStatus(!disconnectStatus)
        await disConnect()

      }

      async function disConnect(){
        setAccounts([]);
        setNames([]);
        setAccount(null);
        // setListdata('Starknet Mainnet');
        if(listData == 'Starknet'){
          await disconnect({ clearLastWallet: true });
        }
        
        // window.location.reload();
        // console.log('disconnect!');
      }
      // function closeAlert(){
      //   setAlert(false);
      // }
      // function Alert(){
      //   return(
      //     <div className='alert_con'>
      //       <div className='alert_child'>
      //            <p><img src={close} onClick={closeAlert}/></p>
      //            <li onClick={connectBraavos}><img src={braavos} />Connect Braavos Wallet</li>
      //            <li onClick={connectArgent}><img src={argent} />Connect Argent Wallet</li>
      //       </div>
      
      //     </div>
      //   )
      // }
      
    return (
      <Maincontext.Provider value={obj}>
        <div>
           <nav>
            <div className="nav-left">
              <a href="https://zk-ns.xyz" ><img src={NGT}/>zk-Name Service</a>
              <li><a href='https://twitter.com/zknsxyz' target="_blank"><img className='discord' src={twitters}/></a></li>
              <li><a href='https://discord.gg/YWrevpKWPx' target="_blank"><img className='discord' src={discords}/></a></li>
            </div>
            <input type="checkbox" id="nav-checkbox"/>
            <label htmlFor="nav-checkbox">≡</label>
            <div className="nav-right">
                <ul>
                    <li  className= {choose === 'domain' ? "chose" : ""} onClick={() =>handerClick('domain')}>My Domain</li>
                    <li className= {choose === 'community' ? "chose" : ""} onClick={()=>handerClick('community')}>Community</li>
                    <li className= {choose === 'game' ? "chose" : ""} onClick={()=>handerClick('game')}>Game <img src={hot_logo}/></li>
                    <li className= {choose === 'bridge' ? "chose" : ""} onClick={()=>handerClick('bridge')}>Bridge <img src={layerzero_logo}/></li>
                </ul>
                <div className="select-box">
                    
                    {/* <select name = "dropdown" value={selectValue}
              onChange={(e)=>handerSelectChange(e)}>
                        <option defaultValue = "Starknet Mainnet" style={{backgroundImage:require("../icons/starknet.png")}}>Starknet Mainnet</option>
                        <option value = "zkSync Era Mainnet">zkSync Era Mainnet</option>
                    </select> */}
                    
                    <div className="select_contain">
                        <div className="select_top" onClick={displayList} >
                            <li><img src={getLogo()}/>{listData}</li>
                            <img src={select_down}/>
                        </div>
                        {listClicks && 
                          <div className="select_list">
                              <li onClick={() =>handerListClick('Starknet')}><img src={starknet_logo}/>Starknet</li>
                              <li onClick={() =>handerListClick('zkSync Era')}><img src={zkSync_Era_logo}/>zkSync Era</li>
                              <li onClick={() =>handerListClick('Polygon zkEVM')}><img src={Polygon_zkEVM_logo}/>Polygon zkEVM</li>
                              <li onClick={() =>handerListClick('Bevm')}><img src={Bevm_logo}/>Bevm</li>
                              <ul onClick={() =>handerListClick('Linea')}><img src={Linea_logo}/>Linea</ul> 
                              {/* <ul onClick={() =>handerListClick('Scroll Testnet')}><img src={scroll_logo}/>Scroll Testnet</ul>*/}
                          </div>
                        }
                          
                    </div>

                    <div className="select_wallet">
                        {/* {isConnected ? <li><HandString /></li> : <button onClick={connectAccount}>Connect Wallet</button>} */}
                        {isConnected ? <button onClick={disConnectDisplay}><HandString /></button> : <button onClick={connectAccount}>Connect Wallet</button>}
                        {disconnectStatus && 
                              <div className="disconnectComponent" onClick={disconnectwallet}>
                                  <li>Disconnect</li>
                              </div>
                            }
                    </div>
                </div>
            </div>
        </nav>
        {choose === 'domain' ? <Mydomain setChoose={setChoose} setNames={setNames}/> : (choose === 'community' ? <Community /> : (choose === 'game' ? <Game listData={listData} disConnect = {disConnect} connectAccount = {connectAccount} setListdata = {setListdata}/> :<Bridge disConnect = {disConnect} listData={listData} setListdata = {setListdata}/>))}
        
    </div>
    </Maincontext.Provider>
    )
}

export default Header