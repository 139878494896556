import React, { Component } from 'react'
import { Contract, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import '../styles/register.css'
import plus from '../icons/plus.png'
import reduce from '../icons/reduce.png'
import Maincontext from './context'
import loading from '../icons/loading.gif'
import { StarknetWallet } from '../store/starknet';
import {ZKsyncWallet } from '../store/zksync';
import {PolygonZKevmWallet } from '../store/polygonzkevm';
import {LineaWallet } from '../store/linea';
import { observer } from 'mobx-react-lite';
import { BevmWallet } from '../store/bevm';

export class Register extends Component {
    state= {
        // network : 'Starknet Mainnet',
        Nums: 1,
        registerStatus: 0
    }
    plusing = () =>{
        if(this.state.Nums < 5){
            this.setState({
                Nums: this.state.Nums + 1
            })
        }
    }
    reducing = () =>{
        if(this.state.Nums > 1){
            this.setState({
                Nums: this.state.Nums - 1
            })
        }
    }
    registDNS = async () =>{
        // this.context.myContract.connect(this.context.account);
        // const provider = new SequencerProvider({
        //     baseUrl: 'https://alpha4.starknet.io',
        //     feederGatewayUrl: 'feeder_gateway',
        //     gatewayUrl: 'gateway',
        //   });
        // const testContractAddress = "0x066170326fc3e110b0486e44e6a2c59873689f9f6576bfb57a26e259eed437e1";
        // const testETHAddress = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7";
        
        if(this.context.address.length > 0){
            if(this.context.network == 'Starknet'){
                try{
                    const amount = uint256.bnToUint256(this.props.basePrice * this.state.Nums);
                    const year = uint256.bnToUint256(this.state.Nums);
                    const multiCall = await this.context.account.execute(
                        [
                        // Calling the first contract
                        {
                        contractAddress: StarknetWallet.testETHAddress,
                        entrypoint: "approve",
                        // approve 1 wei for bridge
                        calldata: CallData.compile({
                            spender: StarknetWallet.testContractAddress,
                            amount: amount,
                        })
                        },
                        // Calling the second contract
                        {
                        contractAddress: StarknetWallet.testContractAddress,
                        entrypoint: "set_ns",
                        calldata: CallData.compile({
                            str: this.props.name,
                            _year: year,
                            version: 0
                        })
                        }
                    ]
                    )
                    //   console.log(multiCall.transaction_hash);
                        this.setState({
                            registerStatus: 1
                        });
                    await StarknetWallet.provider.waitForTransaction(multiCall.transaction_hash);
                        this.props.getNScall()
    
                }catch(e){
                    console.log(e)
                }
            }else if(this.context.network == 'Bevm'){
                try{
                    const amount = this.props.basePrice * this.state.Nums;
                    this.setState({
                        registerStatus: 1
                    });
                    const tx = await BevmWallet.BevmContract.register(this.props.name, this.state.Nums, {value: amount.toString()});
                    await tx.wait();
                    this.props.getNScall()
                }catch(e){
                    console.log(e)
                }
            }else if(this.context.network == 'zkSync Era'){
                try{
                    const amount = this.props.basePrice * this.state.Nums;
                    this.setState({
                        registerStatus: 1
                    });
                    const tx = await ZKsyncWallet.ZKsyncContract.register(this.props.name, this.state.Nums, {value: amount.toString()});
                    await tx.wait();
                    this.props.getNScall()
                }catch(e){
                    console.log(e)
                }
            }else if(this.context.network == 'Polygon zkEVM'){
                try{
                    const amount = this.props.basePrice * this.state.Nums;
                    this.setState({
                        registerStatus: 1
                    });
                    const tx = await PolygonZKevmWallet.PolygonZKevmContract.register(this.props.name, this.state.Nums, {value: amount.toString()});
                    await tx.wait();
                    this.props.getNScall()
                }catch(e){
                    console.log(e)
                }
            }else if(this.context.network == 'Linea'){
                try{
                    const amount = this.props.basePrice * this.state.Nums;
                    this.setState({
                        registerStatus: 1
                    });
                    const tx = await LineaWallet.LineaContract.register(this.props.name, this.state.Nums, {value: amount.toString()});
                    await tx.wait();
                    this.props.getNScall()
                }catch(e){
                    console.log(e)
                }
            }
            
            
        }else{
            alert('Pls connect wallet first!')
        }
        
    }
    
  render() {
    // console.log(this.props.basePrice)
    // console.log(this.context.address)
    return (
        <>
        <div className='parent'>
            <div className='child'>Register Now</div>
        </div>
        <div className='parent'>
            <div className='info'> 
                <div className='info_left'>
                    <div className='info_num'>
                        <img src={reduce} onClick={this.reducing}></img>
                        <li className='year_num'>{this.state.Nums}</li>
                        <img src={plus} onClick={this.plusing}></img>
                    </div>
                    <li >Years</li>
                </div>
                
                <div className='info_right'>
                    <li className='child1'>{(this.props.basePrice * this.state.Nums)/(10**18)} {this.context.network == 'Bevm' ? 'BTC': 'ETH'}</li>
                    <li className='child2'>Register price to pay</li>
                </div>
            </div>
        </div>
        <div className='parent'>
            
            {/* {this.state.registerStatus==0 ? (<div className='register' onClick={this.registDNS}> Register on {this.context.network} </div>): <div className='register2'><img src={loading}/></div>} */}
            {this.state.registerStatus==0 ? (<div className='register' > Register on {this.context.network} </div>): <div className='register2'><img src={loading}/></div>}
        </div>
        </>
   
    )
  }
}
Register.contextType = Maincontext
export default Register