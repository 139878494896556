import React, { Component } from 'react'
import '../styles/community.css'
import Claim_token from './claim_token'
import Stake_token from './stake_token'

export class Community extends Component {
    state = {
      choose: 'claim'
    }

    handerClick = (data) => {
        this.setState({choose: data})
        // console.log('choose:', data)
    }
  render() {
    return (
      <div className='community_parent'>
        <div className='community'>
            <div className='community_child'>
                <li className= {this.state.choose === 'claim' ? "chose" : ""} onClick={() =>this.handerClick('claim')}>Claim Token</li>
                {/* <li><hr/></li> */}
                <hr/>
                <li className= {this.state.choose === 'stake' ? "chose" : ""} onClick={() =>this.handerClick('stake')}>Stake Token</li>
            </div>
        </div> 
        {this.state.choose === 'claim' ? <Claim_token /> : <Stake_token />}
      </div>
    )
  }
}

export default Community