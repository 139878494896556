import React, { useState, useContext, useEffect } from 'react'
import { Contract, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import '../styles/stake_token.css'
import ngt_logo from '../icons/NGT.png'
import loading from '../icons/loading.gif'
import tip from '../icons/tip.png'
import { StarknetWallet } from '../store/starknet'
import {ZKsyncWallet } from '../store/zksync'
import {PolygonZKevmWallet } from '../store/polygonzkevm'
import {LineaWallet } from '../store/linea';
import Maincontext from './context'
import * as ethers from "ethers"

const Stake_token = () => {
    const myAccount = useContext(Maincontext)
    const [balance, setBalance] = useState(0)
    const [stakeStatus, setStakeStatus] = useState(0)
    const [claimStatus, setClaimStatus] = useState(0)
    const [claimIndex, setClaimIndex] = useState(0)
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState('7 days');
    const [contents, setContents] = useState([])
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    // console.log(inputValue)
    const stakeNGT = async()=>{
          if(inputValue* 10**18 > balance*1){
             alert('input over balance')
          }else if(inputValue*1 > 0 && myAccount.address.length > 0){
              if(myAccount.network == 'Starknet'){
                try{
                    let kind = 1
                    if(selectValue == '14 days'){
                      kind = 2
                    }else if(selectValue == '30 days'){
                      kind = 3
                    }
                    const amount = uint256.bnToUint256(inputValue * 10**18);
                    const multiCall = await myAccount.account.execute(
                    [
                    // Calling the first contract
                    {
                    contractAddress: StarknetWallet.testerc20Address,
                    entrypoint: "approve",
                    // approve 1 wei for bridge
                    calldata: CallData.compile({
                        spender: StarknetWallet.teststakecontractAddress,
                        amount: amount,
                      })
                    },
                    // Calling the second contract
                    {
                      contractAddress: StarknetWallet.teststakecontractAddress,
                      entrypoint: "make_order",
                      // transfer 1 wei to the contract address
                      calldata: CallData.compile({
                          amount: amount,
                          kinds: kind,
                      })
                    }
                  ]
                  )
              //   console.log(multiCall.transaction_hash);
                  setStakeStatus(1)
                  await StarknetWallet.provider.waitForTransaction(multiCall.transaction_hash);
                  setStakeStatus(0)
                  getNGTBalance()
                }catch(e){
                  console.log(e)
                }
            }else if(myAccount.network == 'zkSync Era'){
                try{
                  let kind = 1
                  if(selectValue == '14 days'){
                    kind = 2
                  }else if(selectValue == '30 days'){
                    kind = 3
                  }
                  // const amount = inputValue * 10**18;
                  const amount =  ethers.utils.parseEther(inputValue.toString());
                  setStakeStatus(1)
                  const allowance_num = await ZKsyncWallet.ZKsyncErc20Contract.allowance(myAccount.address, ZKsyncWallet.teststakecontractAddress);
              
                  if(allowance_num.toString()*1 < amount.toString()*1){
                    const tx1 = await ZKsyncWallet.ZKsyncErc20Contract.approve(ZKsyncWallet.teststakecontractAddress, amount.toString());
                    await tx1.wait();
                  }
                  
                  const tx2 = await  ZKsyncWallet.ZKsyncStakeContract.makeOrder(amount.toString(), kind);
                  await tx2.wait();
                  setStakeStatus(0)
                  getNGTBalance()
                }catch(e){
                  console.log(e)
                }
          }else if(myAccount.network == 'Polygon zkEVM'){
            try{
              let kind = 1
              if(selectValue == '14 days'){
                kind = 2
              }else if(selectValue == '30 days'){
                kind = 3
              }
              // const amount = inputValue * 10**18;
              const amount =  ethers.utils.parseEther(inputValue.toString());
              setStakeStatus(1)
              const allowance_num = await PolygonZKevmWallet.PolygonZKevmErc20Contract.allowance(myAccount.address, PolygonZKevmWallet.teststakecontractAddress);
              
              if(allowance_num.toString()*1 < amount.toString()*1){
                const tx1 = await PolygonZKevmWallet.PolygonZKevmErc20Contract.approve(PolygonZKevmWallet.teststakecontractAddress, amount.toString());
                await tx1.wait();
              }
              
              const tx2 = await PolygonZKevmWallet.PolygonZKevmStakeContract.makeOrder(amount.toString(), kind);
              await tx2.wait();
              setStakeStatus(0)
              getNGTBalance()
            }catch(e){
              console.log(e)
            }
          }else if(myAccount.network == 'Linea'){
            try{
              let kind = 1
              if(selectValue == '14 days'){
                kind = 2
              }else if(selectValue == '30 days'){
                kind = 3
              }
              // const amount = inputValue * 10**18;
              const amount =  ethers.utils.parseEther(inputValue.toString());
              setStakeStatus(1)
              const allowance_num = await LineaWallet.LineaErc20Contract.allowance(myAccount.address, LineaWallet.teststakecontractAddress);
              
              if(allowance_num.toString()*1 < amount.toString()*1){
                const tx1 = await LineaWallet.LineaErc20Contract.approve(LineaWallet.teststakecontractAddress, amount.toString());
                await tx1.wait();
              }
              
              const tx2 = await LineaWallet.LineaStakeContract.makeOrder(amount.toString(), kind);
              await tx2.wait();
              setStakeStatus(0)
              getNGTBalance()
            }catch(e){
              console.log(e)
            }
          }
        }

    }
    const getNGTBalance = async() => {
      if(myAccount.network == 'Starknet'){
        if(myAccount.address.length>0){
          try{
              const resu1 = await StarknetWallet.StarknetErc20Contract.balanceOf(myAccount.address);
              const resu2 = await StarknetWallet.StarknetStakeContract.get_order_info(myAccount.address);
              setBalance(resu1.toString())
              let arr = []
              for(let i = 0; i < resu2.length; i++){
                let reward = 0
                let endtime = 0
                if(resu2[i].kinds.toString() == 1){
                  reward = (resu2[i].seedmoney.toString())/(10**18) * 0.03
                  endtime = resu2[i].starttime.toString()*1 + 7 *24 * 3600
                }else if(resu2[i].kinds.toString() == 2){
                  reward = (resu2[i].seedmoney.toString())/(10**18) * 0.05
                  endtime = resu2[i].starttime.toString()*1 + 14 *24 * 3600
                }else if(resu2[i].kinds.toString() == 3){
                  reward = (resu2[i].seedmoney.toString())/(10**18) * 0.1
                  endtime = resu2[i].starttime.toString()*1 + 30 *24 * 3600
                }
                arr.push({index: resu2[i].orderindex.toString(), amount: (resu2[i].seedmoney.toString())/(10**18), starttime: resu2[i].starttime.toString(), endtime: endtime, reward: reward})
              }
              setContents(arr)
              // console.log(resu2)
            }catch(e){
              console.log(e)
            }
        }

      }else if(myAccount.network == 'zkSync Era'){
        if(myAccount.address.length>0){
          try{
              const resu1 = await ZKsyncWallet.ZKsyncErc20Contract.balanceOf(myAccount.address);
              const resu2 = await ZKsyncWallet.ZKsyncStakeContract.getOrderInfo(myAccount.address);
              setBalance(resu1.toString())
              let arr = []
              for(let i = 0; i < resu2.length; i++){
                let reward = 0
                let endtime = 0
                if(resu2[i].kinds.toString() == 1){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.03
                  endtime = resu2[i].startTime.toString()*1 + 7 *24 * 3600
                }else if(resu2[i].kinds.toString() == 2){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.05
                  endtime = resu2[i].startTime.toString()*1 + 14 *24 * 3600
                }else if(resu2[i].kinds.toString() == 3){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.1
                  endtime = resu2[i].startTime.toString()*1 + 30 *24 * 3600
                }
                arr.push({index: i + 1, amount: (resu2[i].seedMoney.toString())/(10**18), starttime: resu2[i].startTime.toString(), endtime: endtime, reward: reward})
              }
              setContents(arr)
              // console.log(resu2)
            }catch(e){
              console.log(e)
            }
        }
      }else if(myAccount.network == 'Polygon zkEVM'){
        if(myAccount.address.length>0){
          try{
              const resu1 = await PolygonZKevmWallet.PolygonZKevmErc20Contract.balanceOf(myAccount.address);
              const resu2 = await PolygonZKevmWallet.PolygonZKevmStakeContract.getOrderInfo(myAccount.address);
              setBalance(resu1.toString())
              let arr = []
              for(let i = 0; i < resu2.length; i++){
                let reward = 0
                let endtime = 0
                if(resu2[i].kinds.toString() == 1){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.03
                  endtime = resu2[i].startTime.toString()*1 + 7 *24 * 3600
                }else if(resu2[i].kinds.toString() == 2){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.05
                  endtime = resu2[i].startTime.toString()*1 + 14 *24 * 3600
                }else if(resu2[i].kinds.toString() == 3){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.1
                  endtime = resu2[i].startTime.toString()*1 + 30 *24 * 3600
                }
                arr.push({index: i + 1, amount: (resu2[i].seedMoney.toString())/(10**18), starttime: resu2[i].startTime.toString(), endtime: endtime, reward: reward})
              }
              setContents(arr)
              // console.log(resu2)
            }catch(e){
              console.log(e)
            }
        }
      }else if(myAccount.network == 'Linea'){
        if(myAccount.address.length>0){
          try{
              const resu1 = await LineaWallet.LineaErc20Contract.balanceOf(myAccount.address);
              const resu2 = await LineaWallet.LineaStakeContract.getOrderInfo(myAccount.address);
              setBalance(resu1.toString())
              let arr = []
              for(let i = 0; i < resu2.length; i++){
                let reward = 0
                let endtime = 0
                if(resu2[i].kinds.toString() == 1){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.03
                  endtime = resu2[i].startTime.toString()*1 + 7 *24 * 3600
                }else if(resu2[i].kinds.toString() == 2){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.05
                  endtime = resu2[i].startTime.toString()*1 + 14 *24 * 3600
                }else if(resu2[i].kinds.toString() == 3){
                  reward = (resu2[i].seedMoney.toString())/(10**18) * 0.1
                  endtime = resu2[i].startTime.toString()*1 + 30 *24 * 3600
                }
                arr.push({index: i + 1, amount: (resu2[i].seedMoney.toString())/(10**18), starttime: resu2[i].startTime.toString(), endtime: endtime, reward: reward})
              }
              setContents(arr)
              // console.log(resu2)
            }catch(e){
              console.log(e)
            }
        }
      }
      
    }
    
    useEffect ( ()=>{
      getNGTBalance()

    }, [myAccount.address]);
    function handerSelectChange(e){
      setSelectValue(e.target.value);
    }

    const ParsTime=(time)=>{
      let timestamp = time
      // 此处时间戳以毫秒为单位
      if(time == 0){
        return 0
      }else{
        let date = new Date(parseInt(timestamp) * 1000);
        let Year = date.getFullYear();
        let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        let  GMT =  Year + '-' + Moth + '-' + Day + '   '+ Hour +':'+ Minute  + ':' + Sechond;
        
        return (GMT + ' GMT')
      }
      

    }
    const claimStakeNGT = async(data) => {
        // console.log(data, 'do claim stake ngt')
        if(myAccount.network == 'Starknet'){
            try{
                const multiCall = await myAccount.account.execute(
                  [
                    {
                      contractAddress: StarknetWallet.teststakecontractAddress,
                      entrypoint: "claim_order",
                      // transfer 1 wei to the contract address
                      calldata: CallData.compile({
                        _index: data,
                      })
                    }
                ]
                )
                setClaimStatus(1)
                setClaimIndex(data)
                await StarknetWallet.provider.waitForTransaction(multiCall.transaction_hash);
                setClaimStatus(0)
                setClaimIndex(0)
                getNGTBalance()
            }catch(e){
                console.log(e)
            }
        }else if(myAccount.network == 'zkSync Era'){
          try{
            
            setClaimStatus(1)
            setClaimIndex(data)
            const tx1 = await ZKsyncWallet.ZKsyncStakeContract.claimOrder(data - 1);
            await tx1.wait();
            setClaimStatus(0)
            setClaimIndex(0)
            getNGTBalance()
          }catch(e){
              console.log(e)
          }
        }else if(myAccount.network == 'Polygon zkEVM'){
          try{
            
            setClaimStatus(1)
            setClaimIndex(data)
            const tx1 = await PolygonZKevmWallet.PolygonZKevmStakeContract.claimOrder(data - 1);
            await tx1.wait();
            setClaimStatus(0)
            setClaimIndex(0)
            getNGTBalance()
          }catch(e){
              console.log(e)
          }
        }else if(myAccount.network == 'Linea'){
          try{
            
            setClaimStatus(1)
            setClaimIndex(data)
            const tx1 = await LineaWallet.LineaStakeContract.claimOrder(data - 1);
            await tx1.wait();
            setClaimStatus(0)
            setClaimIndex(0)
            getNGTBalance()
          }catch(e){
              console.log(e)
          }
        }
    }
    return (
        <div className='stake_token_parent'>
            <div className='stake_token'>
                <div className='stake_token_child'>
                    <div className='stake_token_contain'>
                      <div className='left'>
                          <input placeholder='Input Stake Amount' value={inputValue}
                                  onChange={e => setInputValue(e.target.value)} />
                          <li><img src={ngt_logo} />NGT</li>
                      </div>
                      <div className='left_amount'>
                          <li >Available Amount: {(parseInt((balance/(10**18))*100)/100).toString()} NGT</li>
                      </div>
                    </div>
                  
                    <div className='mid'>
                        <select name = "dropdown" value={selectValue}
                                    onChange={(e)=>handerSelectChange(e)}>
                            <option defaultValue = "7 days" >7 days with 3% profit</option>
                            <option value = "14 days">14 days with 5% profit</option>
                            <option value = "30 days">30 days with 10% profit</option>
                        </select>
                    </div>

                    <div className='right'>
                        {stakeStatus == 0 ? <li onClick={stakeNGT}>Stake</li> : <img src={loading} />}
                    </div>
                </div>
                
            </div> 
            
            <div className='stake_info'>
                <div className='stake_info_child'>
                   <div className='info_title'>
                      <li>ID</li>
                      <li>Stake Amount</li>
                      <li>Stake Time</li>
                      <li>Expiration</li>
                      <li>Reward <img onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} src={tip}/>
                      {isHovered && <div className='tips'>When staking is expired, you will get staked amount plus
                      staking reward.<br/> Otherwise, you will get staked amount minus staking reward. 
                      <br/>So you'd better claim when staking is expired.</div>}
                      </li>
                      
                      <ul></ul>
                    </div>
                    {contents.map(item => (<div className='info_content' key = {item.index}>
                      <li>{item.index}</li>
                      <li>{(item.amount).toFixed(2)} NGT</li>
                      <li>{ParsTime(item.starttime)}</li>
                      <li>{ParsTime(item.endtime)}</li>
                      <li className='rewards'>{(item.reward).toFixed(2)} NGT</li>
                      {(claimStatus == 1 && claimIndex == item.index) ? <ul><img src={loading}/></ul> : (item.amount == 0 ? <ul><div className='claimed'>Claimed</div></ul> : <ul><li onClick={() => claimStakeNGT(item.index)}>Claim</li></ul>) }
                    </div>))}
                </div>
                    
            </div>
       </div>
    )
}
// Stake_token.contextType = Maincontext
export default Stake_token