import React, { useEffect, useRef } from "react";
import { contractClassResponseToLegacyCompiledContract } from "starknet";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"; 
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';//加载压缩模型
import { AnimationClip, AnimationMixer } from "three";//动画混合器
// import modl from '../icons/egg.glb'

const Chick = () => {
    const ref = useRef();

    const rotateBox = () => {

        // 创建 scene 场景
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x062134);
        // scene.background = new THREE.Color(0x000000);
    
        // 创建相机
        
        const camera = new THREE.PerspectiveCamera(60, window.innerWidth/window.innerHeight, 1, 1000);
    
        // 设置相机的z位置为5 ，位置：（0，0，5)
        // camera.position.z = 5;
        // camera.position.z = 10;
		    // camera.position.y = 5;
        //调整摄像头的位置，应与模型位置一起调整
        if(window.innerWidth < 700){
           camera.position.set(2, 5, 21.8); 
        }else{
           camera.position.set(1.6, 3, 12.5); 
        }
        

        //创建自定义材质
        const material_cus = new THREE.MeshPhongMaterial({color: 0xff3300});


        // // 创建绿色立方体
        // const geometry = new THREE.BoxGeometry(2, 2, 2);
        // const material = new THREE.MeshBasicMaterial({ color: 0x00ffaa });
        // const cube = new THREE.Mesh(geometry, material);
        // cube.position.set(0,1,0);
        // 创建GLTF加载器对象
        // const loader = new GLTFLoader(); 
        // loader.load( '/chicken03.glb', function (gltf) {
        //   // console.log('控制台查看加载gltf文件返回的对象结构',gltf);
        //   // console.log('gltf对象场景属性',gltf.scene);
        //   //调整外部模型在整体渲染场景中间的位置
        //   gltf.scene.position.set(0, -0.6, 0);
        //   // gltf.scene.scale.set(1,1,1);
        //   // 返回的场景对象gltf.scene插入到threejs场景中
        //   //遍历模型
        //   gltf.scene.traverse(function (child){
        //     // console.log(child);
        //     if(child.name.includes('egg00')){
        //       child.material = material_cus
        //     }           
            
        //   })
        //   scene.add( gltf.scene );
        // })
        const loader = new GLTFLoader(); 
        //对模型解压
        const dracoloader = new DRACOLoader();
        dracoloader.setDecoderPath("/draco/");//把examples\jsm\libs\draco这个文件夹复制过来
        dracoloader.setDecoderConfig({ type: "js" })
        dracoloader.preload();

        loader.setDRACOLoader(dracoloader);//注入loader

        loader.load( '/chicken03-12.glb', function (gltf) {
          scene.add( gltf.scene );
          if(window.innerWidth < 700){
            gltf.scene.position.set(0, -4, 0); 

          }else{
            gltf.scene.position.set(0, -6.6, 0); 
          }
          
          const mixer = new AnimationMixer(gltf.scene);
          // console.log(gltf.animations)
          //找到待机的动画切片
          // const idle = gltf.animations.find(({name}) => name == "idle");
          const idle = gltf.animations.find(({name}) => name == "chickenrigAction.003");
          mixer.clipAction(idle).play();

          const clock = new THREE.Clock();

          //找到物体
          const SkinnedMesh = gltf.scene.getObjectByProperty(
            "type",
            "SkinnedMesh"
          );
          SkinnedMesh.onBeforeRender = () =>{
            //按每一帧播放
            const delta = clock.getDelta();
            mixer.update(delta);
          }
          
          // console.log(gltf)
        })

        //添加环境光
        // const light = new THREE.AmbientLight(0x404040, 30);
        const light = new THREE.AmbientLight(0xe2e2e2, 1);
        scene.add(light);

        //添加平行光
        const derectLight = new THREE.DirectionalLight(0xffffff, 4);
        derectLight.position.set(-5,10,10);
        // derectLight.castShadow = true;
        scene.add(derectLight);

        // //添加平行光
        // const derectLight1 = new THREE.DirectionalLight(0x8f9293, 10);
        // derectLight1.position.set(0,0,10);
        // scene.add(derectLight1);
        
        // scene.add(cube);
    
        // 创建渲染器
        const renderer = new THREE.WebGLRenderer(
          //增加下面两个属性，可以抗锯齿
          {
            antialias:true,
            alpha:true
          }
        );
        // console.log(window.innerWidth);
        if(window.innerWidth < 700){
          // renderer.setSize(window.innerWidth/1.4, window.innerHeight/2);
          renderer.setSize(window.innerWidth/1.4, window.innerHeight/1.4);
          // renderer.setSize(300, 300);
        }else{
          renderer.setSize(window.innerWidth /2.6, window.innerHeight/2.6);
          // renderer.setSize(500, 400);
        }
        
        //设置设备像素比
        renderer.setPixelRatio(window.devicePixelRatio);
        // console.log(window.devicePixelRatio);//1.25

        //设置色调映射
        // renderer.outputEncoding = THREE.sRGBEncoding;

        document.getElementById('chick_id').appendChild(renderer.domElement);

        // //添加网格地面
        // const gridHelper = new THREE.GridHelper(5,5);
        // scene.add(gridHelper);

        //轨道控制器，鼠标能移动
        const orbitControls = new OrbitControls(camera, renderer.domElement);

        //添加阻尼
        orbitControls.enableDamping = true;
        orbitControls.dampingFactor = 0.02;

        //自动旋转
        // orbitControls.autoRotate = true;
        // orbitControls.autoRotateSpeed = 1.2;


    
        // 让立方体渲染起来
        function animate() {
          requestAnimationFrame(animate);
    
          // cube.rotation.x += 0.01;
          // cube.rotation.y += 0.01;

          orbitControls.update()
          renderer.render(scene, camera);
          
        }
    
        animate();
      };
      useEffect(() => {
        rotateBox();
      }, []);
    
      return (
        <div id='chick_id'></div>
      )
  
};

export default Chick;