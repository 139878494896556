import { Contract, RpcProvider, constants, Provider, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet"
import nsAbi from "../json/starknet_ns_json"
import erc20Abi from "../json/starknet_erc20_json"
import stakeAbi from "../json/starknet_stake_json"
import mintAbi from "../json/starknet_mint_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    // provider = new SequencerProvider({
    //     baseUrl: 'https://alpha4.starknet.io',
    //     feederGatewayUrl: 'feeder_gateway',
    //     gatewayUrl: 'gateway',
    // })
    // provider = new RpcProvider({ nodeUrl: "https://starknet-goerli.g.alchemy.com/v2/p3c4WtBl5AVlTf4eHjkUu-1oIV9sPutU" });
    // provider = new Provider({ sequencer: { network: constants.NetworkName.SN_MAIN } })
    // provider = new RpcProvider({ nodeUrl: "https://starknet-mainnet.g.alchemy.com/v2/FtUqiAtCzg95I7QlnmfigBPwNm3MAE9K" });
    provider = new RpcProvider({ nodeUrl: "https://starknet-mainnet.g.alchemy.com/starknet/version/rpc/v0.5/FtUqiAtCzg95I7QlnmfigBPwNm3MAE9K" });
    //testnet
    // testContractAddress = "0x066170326fc3e110b0486e44e6a2c59873689f9f6576bfb57a26e259eed437e1"
    // testETHAddress = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"
    // testerc20Address = "0x032888604dfad35b85851f3d57cad13be447dfc9343db2592dcc9572c5d4429f"
    // teststakecontractAddress = "0x03b2314298b11c45fc42f819f53a0d3e23a3d76908848c80d7d007d40b71af2c"
    //mainnet
    // testContractAddress = "0x0378886a3d6c2f32b6342020fbd2b477c5a7e80d8a14bebac6f8dab7985b8e1a"
    // testETHAddress = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"
    // testerc20Address = "0x03fa7907cb7892ea3cd90d0fc7b5b6cdae13c393830bf2a7e6a30b42767a1e3f"
    // teststakecontractAddress = "0x064178ef90e89ad301a9851b6068eb8f852c6d0134a4448fa5444c64f0d6c097"
    //mainnet v2
    testContractAddress = "0x00d7e0d63b84379a2e6790d916a55c764842b93d637ed522abe71579def3dc69"
    freeMintContractAddress = "0x03340e087a0d3fb6b248d40aca3d1fe95026612c0ffa61946bab4d2b3cbc4ec8"
    // testContractAddress = "0x0075a115cac5640cb270d3d2d03192aae430610d92f26547785b535fb7780c56"//test
    // freeMintContractAddress = "0x00f2679147c0ffa760df619e9d0ac99e19c78a28261ef2ab588e798f3500dc05"//test

    testETHAddress = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"
    testerc20Address = "0x073a95938e5b2191aaa63c85c668d3bdd115574c829d22f503bbd4f6ca47f456"
    teststakecontractAddress = "0x03af9e582cebfba51f647ad94945957a2c74f880fad01dff1ee58cefc37d90fa"



    StarknetContract = new Contract(nsAbi, this.testContractAddress, this.provider)
    StarknetFreeMintContract = new Contract(mintAbi, this.freeMintContractAddress, this.provider)
    StarknetErc20Contract = new Contract(erc20Abi, this.testerc20Address, this.provider);
    StarknetStakeContract = new Contract(stakeAbi, this.teststakecontractAddress, this.provider);
    constructor(){
        makeAutoObservable(this)
    }

}
const StarknetWallet = new Wallet()

export {StarknetWallet}