import React, { Component, useContext, useState } from 'react'
import '../styles/community_leader.css'
import Community_leader from './community_leader'
import Maincontext from './context'
import { StarknetWallet } from '../store/starknet'
import {ZKsyncWallet } from '../store/zksync'
import {PolygonZKevmWallet } from '../store/polygonzkevm'
import {LineaWallet } from '../store/linea'
// import * as ethers from "ethers";
import { Contract, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import loading from '../icons/loading.gif'



function GetContent({setHasLeader, effectAddress}){
  const myAccount = useContext(Maincontext);
  const [becomeStatus, setBecomeStatus] = useState(0);

  const becomeLeader = async() =>{
    
    if(myAccount.address.length > 0){
      if(!effectAddress){
        alert("claim token to become effect address first!");
      }else{
          if(myAccount.network == 'Starknet'){
              try{
                  const amount = uint256.bnToUint256(10000000000000000);
                  const multiCall = await myAccount.account.execute(
                    [
                    // Calling the first contract
                    {
                    contractAddress: StarknetWallet.testETHAddress,
                    entrypoint: "approve",
                    // approve 1 wei for bridge
                    calldata: CallData.compile({
                        spender: StarknetWallet.testContractAddress,
                        amount: amount,
                      })
                    },
                    // Calling the second contract
                    {
                      contractAddress: StarknetWallet.testContractAddress,
                      entrypoint: "become_community_leader",
                      // transfer 1 wei to the contract address
                      calldata: CallData.compile({version: 0})
                    }
                  ]
                  )
                  setBecomeStatus(1);
                  await StarknetWallet.provider.waitForTransaction(multiCall.transaction_hash);
                  setBecomeStatus(0);
                  setHasLeader(true);
              }catch(e){
                console.log(e)
              }
          }else if(myAccount.network == 'zkSync Era'){
              try{
                  const amount = 10000000000000000;
                  setBecomeStatus(1);
                  const tx = await ZKsyncWallet.ZKsyncContract.becomeCommunityLeader({
                    // 'gasLimit': 2350000,
                    // 'value' : ethers.utils.parseEther("0.01") // 0.06 ether
                    'value' : amount.toString() // 0.06 ether
                    });
                  await tx.wait();
                  setBecomeStatus(0);
                  setHasLeader(true);
              }catch(e){
                  console.log(e)
              }
          }else if(myAccount.network == 'Polygon zkEVM'){
            try{
                const amount = 10000000000000000;
                setBecomeStatus(1);
                const tx = await PolygonZKevmWallet.PolygonZKevmContract.becomeCommunityLeader({
                  'value' : amount.toString() // 0.06 ether
                  });
                await tx.wait();
                setBecomeStatus(0);
                setHasLeader(true);
            }catch(e){
                console.log(e)
            }
        }else if(myAccount.network == 'Linea'){
          try{
              const amount = 10000000000000000;
              setBecomeStatus(1);
              const tx = await LineaWallet.LineaContract.becomeCommunityLeader({
                'value' : amount.toString() // 0.06 ether
                });
              await tx.wait();
              setBecomeStatus(0);
              setHasLeader(true);
          }catch(e){
              console.log(e)
          }

      }

      }
    }else{
      alert('Pls conncet wallet first!')
    }
  
  }

  return(
    <div className='leader_info'>
      <div className='price_child'>
        <li className='left'>Price:</li>
        <li className='mid'>{myAccount.network == 'Bevm' ? '0.002 BTC' :'0.01 ETH'}</li>
        <li className='space'></li>
        {becomeStatus == 0 ? <li className='right' onClick={becomeLeader}>Be A Leader</li> : <div className='right'><img src={loading}/></div>}
      </div>
    </div>
  )

}

export class Be_leader extends Component {
    // state={
    //   hasLeader: false
    // }
    render() {
        return (
            <div>
                <div className='leader_info'>
                  <div className='leader_info_child'>
                    <p>Community Leaders</p>
                  </div>
                </div> 
               {this.props.hasLeader ? <Community_leader /> : <GetContent effectAddress = {this.props.effectAddress} setHasLeader = {this.props.setHasLeader}/>}
                
            </div>
        
        )
      }
}

export default Be_leader