import { Provider } from "zksync-web3";
import * as ethers from "ethers";
import nsAbi from "../json/zksync_ns_json"
import erc20Abi from "../json/zksync_erc20_json"
import stakeAbi from "../json/zksync_stake_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    
    providers = new Provider('https://mainnet.era.zksync.io')
    
    testContractAddress = "0x9417b935f07dCA3FE387069633331027C3b21373"
    testerc20Address = "0x1de8d3221E58734130a94950Ad9bFdc43EBd5fCd"
    teststakecontractAddress = "0x4B9CA8872F07A879B2EE1F0a7aEF2ebe0c194767"

    chainID = 165

    // provider = new ethers.providers.Web3Provider(
    //     window.ethereum,
    // )
    // signer = this.provider.getSigner()
    
    ZKsyncContractInit = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    ZKsyncContract = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    ZKsyncErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.providers)
    ZKsyncStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.providers)
    
    constructor(){
        makeAutoObservable(this)
    }
    reset() {
        this.provider = new ethers.providers.Web3Provider(
            window.ethereum,
        )
        this.signer = this.provider.getSigner()
        this.ZKsyncContract = new ethers.Contract(this.testContractAddress, nsAbi, this.signer)
        this.ZKsyncErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.signer)
        this.ZKsyncStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.signer)
      }

}
const ZKsyncWallet = new Wallet()

export {ZKsyncWallet}