import * as ethers from "ethers";
import nsAbi from "../json/polygonzkevm_ns_json"
import erc20Abi from "../json/polygonzkevm_erc20_json"
import stakeAbi from "../json/polygonzkevm_stake_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    
    providers = new ethers.providers.JsonRpcProvider('https://polygon-zkevm.drpc.org')
    
    testContractAddress = "0xa8cccb1be30604e69d5014022b56aed8b247bfb0"
    testerc20Address = "0x3971797b7178d0f782e0b5477d53af922ebedfea"
    teststakecontractAddress = "0xb2B8578c1D7BC82875721A5346CcE565Bc4Aa455"

    // provider = new ethers.providers.Web3Provider(
    //     window.ethereum,
    // )
    // signer = this.provider.getSigner()

    chainID = 158

    PolygonZKevmContractInit = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    PolygonZKevmContract = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    PolygonZKevmErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.providers)
    PolygonZKevmStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.providers)
    
    constructor(){
        makeAutoObservable(this)
    }
    reset() {
        this.provider = new ethers.providers.Web3Provider(
            window.ethereum,
        )
        this.signer = this.provider.getSigner()
        this.PolygonZKevmContract = new ethers.Contract(this.testContractAddress, nsAbi, this.signer)
        this.PolygonZKevmErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.signer)
        this.PolygonZKevmStakeContract = new ethers.Contract(this.teststakecontractAddress, stakeAbi, this.signer)
      }

}
const PolygonZKevmWallet = new Wallet()

export {PolygonZKevmWallet}