import React, { useState, useContext, useEffect } from 'react'
import '../styles/bridge.css'
import NGT from '../icons/NGT.png'
import bridge_png from '../icons/bridge.png'
import starknet_logo from '../icons/starknet.png'
import zkSync_Era_logo from '../icons/zkSync-Era.png'
import Polygon_zkEVM_logo from '../icons/Polygon-zkEVM.png'
import Celo_logo from '../icons/celo.png'
import CoreDao_logo from '../icons/coredao.png'
import Klaytn_logo from '../icons/klaytn.png'
import Gnosis_logo from '../icons/gnosis.png'
import Linea_logo from '../icons/Linea.png'
import Bevm_logo from '../icons/bevm50.png'
import layerzero_logo from '../icons/layerzero.png'
import layerzero_logo1 from '../icons/layerzero1.png'
import Close from '../icons/close.png'
import loading from '../icons/loading.gif'
import select_down from '../icons/select_down.png'
import arrow from '../icons/arrow.png'
import linktx from '../icons/linktx.png'
import {ZKsyncWallet } from '../store/zksync'
import {PolygonZKevmWallet } from '../store/polygonzkevm'
import {PolygonWallet } from '../store/polygon'
import {CeloWallet } from '../store/celo';
import {KlaytnWallet } from '../store/klaytn';
import {CoreDaoWallet } from '../store/coredao';
import {GnosisWallet } from '../store/gnosis';
import {LineaWallet } from '../store/linea';
import { StarknetWallet } from '../store/starknet'
import Maincontext from './context'
import * as ethers from "ethers"
import {createClient, waitForMessageReceived} from '@layerzerolabs/scan-client'

const Bridge = ({setListdata, disConnect, listData}) =>  {
  const [inputValue, setInputValue] = useState('');
  const [balance, setBalance] = useState('');
  const [bridgeHash, setBridgeHash] = useState('');
  const [alerts, setAlert] = useState(false);
  const [canBridge, setCanBridge] = useState(true);
  const [listClicksLeft, setListClicksLeft] = useState(false)
  const [listClicksRight, setListClicksRight] = useState(false)
  const [listDataLeft, setListdataLeft] = useState(listData)
  const [listDataRight, setListdataRight] = useState('Polygon zkEVM')
  const [bridgeStatus, setBridgeStatus] = useState(0);
  const myAccount = useContext(Maincontext);
  const client = createClient('testnet');

  const displayListLeft = () => {
    setListClicksLeft(!listClicksLeft)
  }
  const displayListRight = () => {
    setListClicksRight(!listClicksRight)
  }
  const handerListClickLeft = async(msg) =>{
    if(msg == listDataLeft){
      // console.log('same, not do')
    }else{
      if(msg == listDataRight){
        // setListdataRight('')
      }else{
        setListdataLeft(msg)
        setListClicksLeft(!listClicksLeft)
        checkCanBridge(msg, listDataRight)
        if(myAccount.address.length > 0){
          disConnect()
        }
        setListdata(msg)
      }
      setBalance('')
    }
    
  }
  const handerListClickRight = async(msg) =>{
    if(msg == listDataRight){
      
    }else{
      if(msg == listDataLeft){

      }else{
        setListdataRight(msg)
        setListClicksRight(!listDataRight)
        checkCanBridge(listDataLeft, msg)
      }
    }
  }
  const getLogoLeft=() =>{
    if(listDataLeft == 'Starknet'){
      return starknet_logo
    }else if(listDataLeft == 'zkSync Era'){
      return zkSync_Era_logo
    }else if(listDataLeft == 'Polygon zkEVM'){
      return Polygon_zkEVM_logo
    }else if(listDataLeft == 'Celo'){
      return Celo_logo
    }else if(listDataLeft == 'Polygon'){
      return Polygon_zkEVM_logo
    }else if(listDataLeft == 'CoreDao'){
      return CoreDao_logo
    }else if(listDataLeft == 'Klaytn'){
      return Klaytn_logo
    }else if(listDataLeft == 'Gnosis'){
      return Gnosis_logo
    }else if(listDataLeft == 'Linea'){
      return Linea_logo
    }else if(listDataLeft == 'Bevm'){
      return Bevm_logo
    // }else if(listDataLeft == 'Scroll Testnet'){
    //   return scroll_logo
    }

  }
  const getLogoRight=() =>{
    if(listDataRight == 'Starknet'){
      return starknet_logo
    }else if(listDataRight == 'zkSync Era'){
      return zkSync_Era_logo
    }else if(listDataRight == 'Polygon zkEVM'){
      return Polygon_zkEVM_logo
    }else if(listDataRight == 'Celo'){
      return Celo_logo
    }else if(listDataRight == 'Polygon'){
      return Polygon_zkEVM_logo
    }else if(listDataRight == 'CoreDao'){
      return CoreDao_logo
    }else if(listDataRight == 'Klaytn'){
      return Klaytn_logo
    }else if(listDataRight == 'Gnosis'){
      return Gnosis_logo
    }else if(listDataRight == 'Linea'){
      return Linea_logo
    // }else if(listDataRight == 'Scroll Testnet'){
    //   return scroll_logo
    }

  }
  const MaxBalance = () =>{
    setInputValue((parseInt((balance/(10**18))*100)/100).toString())
  }
  const ChangeChain= () =>{
    
    setListdataLeft(listDataRight)
    setListdataRight(listDataLeft)
    
    if(myAccount.address.length > 0){
        disConnect()
      }
    setListdata(listDataRight)
    // getBalance()
    setBalance('')
    
  }

  const getBalance = async() => {
    let contract;
    if(myAccount.network == 'zkSync Era'){
      contract = ZKsyncWallet.ZKsyncErc20Contract
    }else if(myAccount.network == 'Polygon zkEVM'){
      contract = PolygonZKevmWallet.PolygonZKevmErc20Contract
    }else if(myAccount.network == 'Celo'){
      contract = CeloWallet.CeloErc20Contract
    }else if(myAccount.network == 'Polygon'){
      contract = PolygonWallet.PolygonErc20Contract
    }else if(myAccount.network == 'Linea'){
      contract = LineaWallet.LineaErc20Contract
    }else if(myAccount.network == 'Klaytn'){
      contract = KlaytnWallet.KlaytnErc20Contract
    }else if(myAccount.network == 'CoreDao'){
      contract = CoreDaoWallet.CoreDaoErc20Contract
    }else if(myAccount.network == 'Gnosis'){
      contract = GnosisWallet.GnosisErc20Contract
    }else if(myAccount.network == 'Starknet'){
      contract = StarknetWallet.StarknetErc20Contract
    }
    if(myAccount.address.length > 0 && contract){
          try{
            const res2 = await contract.balanceOf(myAccount.address);
            setBalance(res2.toString())
          }catch(e){
              console.log(e)
          }
    }

  }
  const doBridge = async() => {
    if(myAccount.address.length > 0){
        let contract;
        let remoteID;
        let localID;
        if(listDataLeft == 'zkSync Era'){
          contract = ZKsyncWallet.ZKsyncErc20Contract;
          localID = ZKsyncWallet.chainID;
        }else if(listDataLeft == 'Polygon zkEVM'){
          contract = PolygonZKevmWallet.PolygonZKevmErc20Contract;
          localID = PolygonZKevmWallet.chainID;
        }else if(listDataLeft == 'Linea'){
          contract = LineaWallet.LineaErc20Contract;
          localID = LineaWallet.chainID;
        }else if(listDataLeft == 'Celo'){
          contract = CeloWallet.CeloErc20Contract;
          localID = CeloWallet.chainID;
        }else if(listDataLeft == 'Polygon'){
          contract = PolygonWallet.PolygonErc20Contract;
          localID = PolygonWallet.chainID;
        }else if(listDataLeft == 'CoreDao'){
          contract = CoreDaoWallet.CoreDaoErc20Contract;
          localID = CoreDaoWallet.chainID;
        }else if(listDataLeft == 'Klaytn'){
          contract = KlaytnWallet.KlaytnErc20Contract;
          localID = KlaytnWallet.chainID;
        }else if(listDataLeft == 'Gnosis'){
          contract = GnosisWallet.GnosisErc20Contract;
          localID = GnosisWallet.chainID;
        }

        if(listDataRight == 'zkSync Era'){
          remoteID = ZKsyncWallet.chainID;
        }else if(listDataRight == 'Polygon zkEVM'){
          remoteID = PolygonZKevmWallet.chainID;
        }else if(listDataRight == 'Linea'){
          remoteID = LineaWallet.chainID;
        }else if(listDataRight == 'Celo'){
          remoteID = CeloWallet.chainID;
        }else if(listDataRight == 'Polygon'){
          remoteID = PolygonWallet.chainID;
        }else if(listDataRight == 'CoreDao'){
          remoteID = CoreDaoWallet.chainID;
        }else if(listDataRight == 'Klaytn'){
          remoteID = KlaytnWallet.chainID;
        }else if(listDataRight == 'Gnosis'){
          remoteID = GnosisWallet.chainID;
        }
        
        if(contract && remoteID && localID && (listDataLeft != listDataRight) && canBridge){
          if(balance*1 > 0 ){
            
              if(inputValue*1 <= balance*1 && inputValue*1 > 0){
                
                try{
                  // console.log('doing ', listDataLeft,  'to ', listDataRight)
                  const initialAmount =  ethers.utils.parseEther(inputValue);
                  const percentAmount = initialAmount.div(100);
                  // console.log(initialAmount.toString())
                  // console.log(percentAmount.toString())
                  const AddressBytes32 = ethers.utils.defaultAbiCoder.encode(["address"], [myAccount.address])
                    
                    
                    try{
                      

                      let nativeFee = (await contract.estimateSendFee(remoteID, AddressBytes32, initialAmount, false, "0x")).nativeFee
                    
                      // console.log(nativeFee.toString());
                      setBridgeStatus(1)
                      let tx = await contract.sendFrom(
                          myAccount.address,
                          remoteID,
                          AddressBytes32,
                          initialAmount,
                          percentAmount,
                          [myAccount.address, ethers.constants.AddressZero, "0x"],
                          { value: nativeFee }
                      )
                      await tx.wait()
                      setBridgeHash(tx.hash)
                      setAlert(true)
                      waitForMessageReceived(localID, tx.hash)
                          .then((message) => {
                            // console.log(message)
                            setBridgeStatus(0)
                            getBalance()
                          })
                          .finally(() => {
                            // updateBalances();
                            
                          });
                    }catch(ee){
                      console.log('These two networks cannot cross the bridge temporarily!')
                    }
                  
                }catch(e){
                    console.log(e)
                }
            }else{
              alert('Please enter correct value')
            }
          }else{
            alert('Your NGT balance is not enough')
          }
        }
    }
  }
  const checkCanBridge = (lefts, rights) => {
    // console.log(lefts, rights)
      if(lefts == 'Starknet' || lefts == 'Bevm'){
        setCanBridge(false)
      }else{
        if(lefts == 'Celo'){
           if(rights == 'Polygon' || rights == 'Gnosis' || rights == 'Polygon zkEVM'){
             setCanBridge(true)
           }else{
             setCanBridge(false)
           }
        }else if(lefts == 'zkSync Era'){
          if(rights == 'Polygon' || rights == 'Polygon zkEVM'){
            // console.log(listDataLeft, listDataRight)
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'Polygon zkEVM'){
          if(rights == 'Polygon' || rights == 'zkSync Era' || rights == 'Linea' || rights == 'Celo' || rights == 'Gnosis'){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'Linea'){
          if(rights == 'Polygon' || rights == 'Polygon zkEVM'){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'Gnosis'){
          if(rights == 'Polygon' || rights == 'Polygon zkEVM' || rights == 'Celo' || rights == 'Klaytn'){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'Klaytn'){
          if(rights == 'Gnosis'){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'CoreDao'){
          if(rights == 'Polygon'){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }else if(lefts == 'Polygon'){
          if(rights == 'Gnosis' || rights == 'Celo' || rights == 'CoreDao' || rights == 'Polygon zkEVM' || rights == 'Linea' || rights == 'zkSync Era' ){
            setCanBridge(true)
          }else{
            setCanBridge(false)
          }
        }
      } 
  }
  const closeAlert = () =>{
        setAlert(false);
  }
  const Alerts = () =>{
    return(
      <div className='alert_con'>
        <div className='alert_child'>
              <div className='alert_title'>
                  <li>Bridge Status</li>
                  <p><img className='close_img' src={Close} onClick={closeAlert}/></p>
              </div>
              <div className='alert_content'>
                 <div className='alert_origin'>
                    <li>Origin</li>
                    <img src={getLogoLeft()}/>
                    <li>{listDataLeft}</li>

                 </div>
                 <div className='alert_layerzero'>
                    
                    <div className='layerzero_ngt'>
                      <li>{inputValue} NGT </li>
                      <img src={NGT}/>
                    </div>
                    <img src={arrow}/>
                    <div className='layerzero_ngt'>
                      <img src={layerzero_logo1}/>
                    </div>
                    {bridgeStatus == 0 ? <a href={"https://layerzeroscan.com/tx/" + bridgeHash} target="_blank" className='layerzero_tx'>
                      <li>LayerZero tx</li>
                      <img src={linktx}/>
                    </a>: <div className='layerzero_tx'><img src={loading} /></div>}
                 </div>
                 <div className='alert_destination'>
                    <li>Destination</li>
                    <img src={getLogoRight()}/>
                    <li>{listDataRight}</li>
                 </div>
                  
              </div>
              <div className='alert_tail'>
                  <li>Your funds will arrive on your address at the destination chain. <a href={"https://layerzeroscan.com/tx/" + bridgeHash} target="_blank"><br/>Get your bridge transaction on LayerZero scan. </a></li>
              </div>
        </div>
      </div>
    )
  }
  useEffect (()=>{
    if(listData != listDataLeft){
      setListdataLeft(listData)
    }
    // if(listData == 'Polygon zkEVM'){
    //   setListdataRight('zkSync Era')
    //   checkCanBridge(listData, 'zkSync Era')
    // }else{
    //   setListdataRight('Polygon zkEVM')
    //   checkCanBridge(listData, 'Polygon zkEVM')
    // }
    if(listData == listDataRight){
        if(listData == 'Polygon zkEVM'){
          setListdataRight('zkSync Era')
          checkCanBridge(listData, 'zkSync Era')
        }else{
          setListdataRight('Polygon zkEVM')
          checkCanBridge(listData, 'Polygon zkEVM')
        }
   }else{
        checkCanBridge(listData, listDataRight)
   }
    
    getBalance()
    // getLayerzero()

  }, [myAccount.address, myAccount.network]);
  
    return (
      <div className='bridge_parent'>
        <div className='bridge'>
            <div className='bridge_child'>
                <div className='bridge_top'>
                    <li className='bridge_title'>Bridge<img src={layerzero_logo}/></li>
                    <li>Bridge your NGT tokens across different chains by using our custom LayerZero bridge solution.</li>
                </div>
                <div className='bridge_content'>
                   <div className='bridge_chains_parent'>
                        <div className='bridge_chains'>
                          <div className='describe_chains'>
                              <div className='chains_left'>Origin</div>
                              <div className='chains_mid'></div>
                              <div className='chains_right'>Destination</div>
                          </div>
                          <div className='chain_select'>
                              <div className='select_left'>
                                    <div className="bridge_select_contain">
                                        <div className="bridge_select_top" onClick={displayListLeft} >
                                            <li><img src={getLogoLeft()}/>{listDataLeft}</li>
                                            <img src={select_down}/>
                                        </div>
                                        {listClicksLeft && 
                                          <div className="bridge_select_list">
                                              {/* <li onClick={() =>handerListClickLeft('Starknet')}><img src={starknet_logo}/>Starknet</li> */}
                                              <li onClick={() =>handerListClickLeft('zkSync Era')}><img src={zkSync_Era_logo}/>zkSync Era</li>
                                              <li onClick={() =>handerListClickLeft('Polygon zkEVM')}><img src={Polygon_zkEVM_logo}/>Polygon zkEVM</li>
                                              <li onClick={() =>handerListClickLeft('Linea')}><img src={Linea_logo}/>Linea</li>
                                              <li onClick={() =>handerListClickLeft('Celo')}><img src={Celo_logo}/>Celo</li>
                                              <li onClick={() =>handerListClickLeft('Polygon')}><img src={Polygon_zkEVM_logo}/>Polygon</li>
                                              <li onClick={() =>handerListClickLeft('CoreDao')}><img src={CoreDao_logo}/>CoreDao</li>
                                              <li onClick={() =>handerListClickLeft('Klaytn')}><img src={Klaytn_logo}/>Klaytn</li>
                                              <ul onClick={() =>handerListClickLeft('Gnosis')}><img src={Gnosis_logo}/>Gnosis</ul>
                                          </div>
                                        }
                                          
                                    </div>
                              </div>
                              <div className='select_mid'>
                                <img src={bridge_png} onClick={ChangeChain}/>
                              </div>
                              <div className='select_right'>
                                    <div className="bridge_select_contain">
                                        <div className="bridge_select_top" onClick={displayListRight} >
                                            <li><img src={getLogoRight()}/>{listDataRight}</li>
                                            <img src={select_down}/>
                                        </div>
                                        {listClicksRight && 
                                          <div className="bridge_select_list">
                                              {/* <li onClick={() =>handerListClickRight('Starknet')}><img src={starknet_logo}/>Starknet</li> */}
                                              <li onClick={() =>handerListClickRight('zkSync Era')}><img src={zkSync_Era_logo}/>zkSync Era</li>
                                              <li onClick={() =>handerListClickRight('Polygon zkEVM')}><img src={Polygon_zkEVM_logo}/>Polygon zkEVM</li>
                                              <li onClick={() =>handerListClickRight('Linea')}><img src={Linea_logo}/>Linea</li>
                                              <li onClick={() =>handerListClickRight('Celo')}><img src={Celo_logo}/>Celo</li>
                                              <li onClick={() =>handerListClickRight('Polygon')}><img src={Polygon_zkEVM_logo}/>Polygon</li>
                                              <li onClick={() =>handerListClickRight('CoreDao')}><img src={CoreDao_logo}/>CoreDao</li>
                                              <li onClick={() =>handerListClickRight('Klaytn')}><img src={Klaytn_logo}/>Klaytn</li>
                                              <ul onClick={() =>handerListClickRight('Gnosis')}><img src={Gnosis_logo}/>Gnosis</ul>
                                          </div>
                                        }
                                          
                                    </div>
                              </div>
                          </div>
                        </div>
                   </div>
                   <div className='bridge_balance_parent'>
                          <div className='bridge_balance'>
                              <div className='bridge_balance_describe'>
                                  <div className='chains_left'>Bridge</div>
                                  <div className='chains_mid'></div>
                                  <div className='chains_right'>Balance: {parseInt((balance/(10**18))*100)/100}</div>
                              </div>
                              <div className='bridge_balance_input'>
                                  <div className='input_left'>
                                      <input placeholder='0.0' value={inputValue} onChange={e => setInputValue(e.target.value)} />     
                                  </div>
                                  <div className='input_mid'>
                                    <li onClick={MaxBalance}>MAX</li>
                                  </div>
                                  <div className='input_right'>
                                    <img src={NGT}/>
                                    <li>NGT</li>
                                  </div>
                              </div>
                        </div>
                   </div>
                   <div className='bridge_button_parent'>
                       <div className='bridge_button' >
                          {bridgeStatus == 0 ? <li onClick={doBridge}>Bridge</li> : <img src={loading}/>}
                          
                          {/* {bridgeStatus == 1 ? <li onClick={doBridge}>Bridge</li> : (<li>{listDataLeft}<img src={loading}/> LayerZero <img src={loading}/> {listDataRight}</li>)} */}
                        </div>
                   </div>
                   <div className='bridge_tips'>
                    {canBridge ? <li className='bridge_tips_can'>{listDataLeft} can bridge to {listDataRight}</li> : <li className='bridge_tips_cannot'>{listDataLeft} can not bridge to {listDataRight} temporarily</li>}
                    </div>
                </div>
            </div>
        </div> 
        {alerts ? <Alerts />: null}
       
      </div>
    )
} 

export default Bridge