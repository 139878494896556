import React, { Component } from 'react'
import '../styles/domain_unavailable.css'

export class Domain_unavailable extends Component {
  render() {
    // let name = 'william.stark'
    return (
        <div>
            <ul className='domain_notfind_info'>
                <p>This domain is not available!</p>
            </ul> 
            <ul className='domain_notfind_info_input'>
                 <ul className='domain_notfind_info_input_child'>
                    <li>{this.props.name}</li>
                    <li>Unavailable</li>
                  </ul>
            </ul>
        </div>
    
    )
  }
}

export default Domain_unavailable