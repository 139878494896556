import React, { useEffect, useContext ,useState }  from 'react'
import { Contract, Account, ec, json, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import Domain_unavailable from './domain_unavailable'
import Domain_available from './domain_available'
import Register from './register'
import search from '../icons/search.png'
import person from '../icons/person.png'
import chick200 from '../icons/chick-200.png'
import egg200 from '../icons/egg-200.png'
import arrow_img from '../icons/arrow2.png'
import Maincontext from './context'
import Mydomaininfo from './mydomaininfo';
import loading from '../icons/loading.gif'
import { StarknetWallet } from '../store/starknet';
import {ZKsyncWallet } from '../store/zksync';
import {BevmWallet} from '../store/bevm'
import {PolygonZKevmWallet } from '../store/polygonzkevm';
import {LineaWallet } from '../store/linea';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';




const Mydomain = ({setChoose, setNames}) => {

    const [available, setAvailable] = useState(0);
    const [num, setNum] = useState();
    const [dsname, setDsname] = useState(0);
    const [dstime, setDsTime] = useState(0);
    const myAccount = useContext(Maincontext);
    const [inputValue, setInputValue] = useState('');
    const [serchValue, setSerchValue] = useState('');
    const [basePrice, setBasePrice] = useState(0);
    const [searchStatus, setSearchStatus] = useState(0);
    const navigate = useNavigate()
    // const testContractAddress = "0x066170326fc3e110b0486e44e6a2c59873689f9f6576bfb57a26e259eed437e1";
    //     const provider = new SequencerProvider({
    //       baseUrl: 'https://alpha4.starknet.io',
    //       feederGatewayUrl: 'feeder_gateway',
    //       gatewayUrl: 'gateway',
    //     });
    // const myTestContract = new Contract(testAbi, testContractAddress, provider);
    // console.log(contract);
    const getNS = async()=>{
          if(myAccount.network == 'Starknet'){
            // const res1 = await StarknetWallet.StarknetContract.get_total_ns();
            // setNum(res1.toString());
        
            if(myAccount.address.length > 0){
              try{
                const res2 = await StarknetWallet.StarknetContract.get_ns(myAccount.address);
                const myTextDecoded = shortString.decodeShortString(res2);
                
                setDsname(myTextDecoded);
                if(myTextDecoded.length > 0){
                  setNames(myTextDecoded);
                }
                
                const resu3 = await StarknetWallet.StarknetContract.get_ns_time(myTextDecoded);
                setDsTime(resu3.toString());
              }catch(e){
                console.log(e)
              }
            }
          }else if(myAccount.network == 'Bevm'){
            // ZKsyncWallet.reset()
            // try{
            //   const res1 = await BevmWallet.BevmContractInit.get_total_ns();
            //   setNum(res1.toString());
            // }catch(e){
            //   console.log(e)
            // }
            
            
            if(myAccount.address.length > 0){
              try{
                  const res2 = await BevmWallet.BevmContract.getZKns(myAccount.address);
                  setDsname(res2.toString());
                  if(res2.toString().length > 0){
                    setNames(res2.toString());
                  }
                  const resu3 = await BevmWallet.BevmContract.getEntries(res2.toString());
                  // console.log(resu3.registerDate.toString());
                  let expire_time = resu3.registerDate.toString() * 1 + resu3.year * 365 *24 *3600;
                  setDsTime(expire_time);
              }catch(e){
                  console.log(e)
              }
            }
          }else if(myAccount.network == 'zkSync Era'){
            // ZKsyncWallet.reset()
            // try{
            //   const res1 = await ZKsyncWallet.ZKsyncContractInit.get_total_ns();
            //   setNum(res1.toString());
            // }catch(e){
            //   console.log(e)
            // }
            
            
            if(myAccount.address.length > 0){
              try{
                  const res2 = await ZKsyncWallet.ZKsyncContract.getZKns(myAccount.address);
                  setDsname(res2.toString());
                  if(res2.toString().length > 0){
                    setNames(res2.toString());
                  }
                  const resu3 = await ZKsyncWallet.ZKsyncContract.getEntries(res2.toString());
                  // console.log(resu3.registerDate.toString());
                  let expire_time = resu3.registerDate.toString() * 1 + resu3.year * 365 *24 *3600;
                  setDsTime(expire_time);
              }catch(e){
                  console.log(e)
              }
            }
          }else if(myAccount.network == 'Polygon zkEVM'){
            // console.log('do get total ns')
            // try{
            //     const res1 = await PolygonZKevmWallet.PolygonZKevmContractInit.get_total_ns();
            //     setNum(res1.toString());
            // }catch(e){
            //   console.log(e)
            // }
            
            if(myAccount.address.length > 0){
              try{
                  const res2 = await PolygonZKevmWallet.PolygonZKevmContract.getZKns(myAccount.address);
                  setDsname(res2.toString());
                  if(res2.toString().length > 0){
                    setNames(res2.toString());
                  }
                  const resu3 = await PolygonZKevmWallet.PolygonZKevmContract.getEntries(res2.toString());
                  // console.log(resu3.registerDate.toString());
                  let expire_time = resu3.registerDate.toString() * 1 + resu3.year * 365 *24 *3600;
                  setDsTime(expire_time);
              }catch(e){
                  console.log(e)
              }
            }
          }else if(myAccount.network == 'Linea'){
            // console.log('do get total ns')
            // const res1 = await LineaWallet.LineaContractInit.get_total_ns();
            // setNum(res1.toString());
            
            if(myAccount.address.length > 0){
              try{
                  const res2 = await LineaWallet.LineaContract.getZKns(myAccount.address);
                  setDsname(res2.toString());
                  if(res2.toString().length > 0){
                    setNames(res2.toString());
                  }
                  
                  const resu3 = await LineaWallet.LineaContract.getEntries(res2.toString());
                  // console.log(resu3.registerDate.toString());
                  let expire_time = resu3.registerDate.toString() * 1 + resu3.year * 365 *24 *3600;
                  setDsTime(expire_time);
              }catch(e){
                  console.log(e)
              }
            }
          }
          
      
    }
    useEffect ( ()=>{
      setAvailable(0)
      setDsname(0)
      getNS()
      

    }, [myAccount.address, myAccount.network]);

    function getPrice() {
      let base_amount = 0.001;
      if(myAccount.network == 'Bevm'){
        base_amount = 0.00005;
      }
        if(inputValue.length == 2){
          base_amount = base_amount * 5;
        }else if(inputValue.length == 3){
          base_amount = base_amount * 4;
        }else if(inputValue.length == 4){
          base_amount = base_amount * 3;
        }else if(inputValue.length == 5){
          base_amount = base_amount * 2;
        }
        return base_amount
    }

    async function searchNS() {
      
      if(inputValue.length >= 2 && inputValue.length < 25){
        if(myAccount.network == 'Starknet'){
          let has_ = inputValue.match(/^.*[A-Za-z]+.*$/);
          let ns = inputValue + '.stark';
          if(has_==null){
            ns = 'Name must contain at least one letter'
            setSerchValue(ns);
            setAvailable(1);
          }else{
            setSerchValue(ns);
            setSearchStatus(1);
            const resu3 = await StarknetWallet.StarknetContract.get_address(ns);
            const amount = uint256.bnToUint256(1000000000000000);
            const resu4 = await StarknetWallet.StarknetContract.computer_price(inputValue, 1, amount);
            setSearchStatus(0);
            setBasePrice(resu4[0].toString());
            if(resu3.toString() == 0){
              setAvailable(2);
            }else{
              setAvailable(1);
            }
          }
        }else if(myAccount.network == 'Bevm'){
          let ns = inputValue + '.bevm';
          setSerchValue(ns);
          setSearchStatus(1);
          const resu3 = await BevmWallet.BevmContractInit.getEntries(ns);
          let base_amount = getPrice()
          setSearchStatus(0);
          setBasePrice(base_amount*10**18);
          
          if(resu3.resolving == '0x0000000000000000000000000000000000000000'){
            setAvailable(2);
          }else{
            setAvailable(1);
          }
          
        }else if(myAccount.network == 'zkSync Era'){
          let ns = inputValue + '.zks';
          setSerchValue(ns);
          setSearchStatus(1);
          const resu3 = await ZKsyncWallet.ZKsyncContractInit.getEntries(ns);
          let base_amount = getPrice()
          setSearchStatus(0);
          setBasePrice(base_amount*10**18);
          
          if(resu3.resolving == '0x0000000000000000000000000000000000000000'){
            setAvailable(2);
          }else{
            setAvailable(1);
          }
        }else if(myAccount.network == 'Polygon zkEVM'){
          let ns = inputValue + '.pzk';
          setSerchValue(ns);
          setSearchStatus(1);
          const resu3 = await PolygonZKevmWallet.PolygonZKevmContractInit.getEntries(ns);
          let base_amount = getPrice()
          setSearchStatus(0);
          setBasePrice(base_amount*10**18);
          
          if(resu3.resolving == '0x0000000000000000000000000000000000000000'){
            setAvailable(2);
          }else{
            setAvailable(1);
          }
        }else if(myAccount.network == 'Linea'){
          let ns = inputValue + '.linea';
          setSerchValue(ns);
          setSearchStatus(1);
          const resu3 = await LineaWallet.LineaContractInit.getEntries(ns);
          let base_amount = getPrice()
          setSearchStatus(0);
          setBasePrice(base_amount*10**18);
          
          if(resu3.resolving == '0x0000000000000000000000000000000000000000'){
            setAvailable(2);
          }else{
            setAvailable(1);
          }
        }
        
      }else{
        alert('Pls input at least 2 chars and no more than 25 chars')
      }
      
      // console.log(available);
    }
    function handerClick() {
        setChoose('game');
        navigate('/game');
    }
    // function displayAvailable() {
    //   console.log(available);
    //   if(available === 0){
    //      return null;
    //   }else if(available ===1){
    //     return (<>
    //       <Domain_unavailable />
    //      </>)
    //   }else{
    //     return (<>
    //       <Domain_available />
    //      </>)
    //   }
    // }
    onkeydown = (e)=>{
      if (e.key == 'Enter') {
        searchNS()
      }
    }

    return (
            <div className='domain_info_parent'>
                {/* <div className='promotion_game'>
                  <div className='promotion_game_child' onClick={handerClick}>
                    <div className='promotion_img'>
                      <img src={chick200}/>
                      <img src={egg200}/>
                    </div>
                    <div className='promotion_title'>
                      <li>Anyone who owns our domain is eligible to mint 1 zk-Chicken NFT for free.</li>
                      <li className='promotion_title_child'>Go to mint<img src={arrow_img}/></li>
                    </div>
                  </div>
                </div> */}
                <div className='domain_info'>
                  <ul className='domain_info_child'>
                    <li className='left'>Register Your Domain Name:</li>
                    {/* <li className='right'><img src={person}/>Registrations: {num}</li> */}
                    {/* <li className='right'><img src={person}/>Registration</li> */}
                  </ul>
                </div>  
                <div className='domain_info_input'>
                 <div className='domain_info_input_child'>
                    <input placeholder='Input Your Username' value={inputValue}
          onChange={e => setInputValue(e.target.value)} onKeyDown={e => onkeydown(e)}/>
                    {searchStatus == 0 ? <img src={search} onClick={searchNS}/> : <img src={loading} /> }
                  </div>
                </div>
                {available === 0 ? null : (available === 1 ? <Domain_unavailable name = {serchValue} /> : <Domain_available name = {inputValue} resultName = {serchValue} basePrice={basePrice} dsname = {dsname} getNS ={getNS}/>)}
                {/* {dsname === 0 ? <Register /> : <Mydomaininfo ns = {dsname} time = {dstime} />} */}
                {(dsname != 0 && dsname.length > 0) && <Mydomaininfo ns = {dsname} time = {dstime} />}
                
            </div>
            

    )
}

export default observer(Mydomain)