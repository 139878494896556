const nsAbi = [
  {
    "name": "constructor",
    "type": "function",
    "inputs": [
      {
        "name": "_owner",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "change_price",
    "type": "function",
    "inputs": [
      {
        "name": "_price",
        "type": "core::integer::u256"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "change_base_mint",
    "type": "function",
    "inputs": [
      {
        "name": "_base_mint",
        "type": "core::integer::u256"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "change_owner",
    "type": "function",
    "inputs": [
      {
        "name": "_owner",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "set_contract_address",
    "type": "function",
    "inputs": [
      {
        "name": "_ethaddress",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "_ngttokenaddress",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "claim",
    "type": "function",
    "inputs": [
      {
        "name": "_amount",
        "type": "core::integer::u256"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "claim_ngttoken",
    "type": "function",
    "inputs": [
      {
        "name": "recommend",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "become_community_leader",
    "type": "function",
    "inputs": [
      {
        "name": "version",
        "type": "core::integer::u8"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "set_ns",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      },
      {
        "name": "_year",
        "type": "core::integer::u256"
      },
      {
        "name": "version",
        "type": "core::integer::u8"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "cancle_ns",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      }
    ],
    "outputs": [],
    "stateMutability": "external"
  },
  {
    "name": "computer_price",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      },
      {
        "name": "_year",
        "type": "core::integer::u256"
      },
      {
        "name": "nsprice",
        "type": "core::integer::u256"
      }
    ],
    "outputs": [
      {
        "type": "(core::integer::u256, core::integer::u256)"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_amount_of_chars",
    "type": "function",
    "inputs": [
      {
        "name": "len",
        "type": "core::integer::u64"
      },
      {
        "name": "num",
        "type": "core::integer::u256"
      },
      {
        "name": "str",
        "type": "core::felt252"
      }
    ],
    "outputs": [
      {
        "type": "core::integer::u64"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "add_suffix",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      }
    ],
    "outputs": [
      {
        "type": "core::felt252"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_address",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      }
    ],
    "outputs": [
      {
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_reward",
    "type": "function",
    "inputs": [
      {
        "name": "_address",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [
      {
        "type": "core::integer::u256"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_ns_time",
    "type": "function",
    "inputs": [
      {
        "name": "str",
        "type": "core::felt252"
      }
    ],
    "outputs": [
      {
        "type": "core::integer::u64"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_ns",
    "type": "function",
    "inputs": [
      {
        "name": "_address",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [
      {
        "type": "core::felt252"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "check_recommend_effect",
    "type": "function",
    "inputs": [
      {
        "name": "addrs",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [
      {
        "type": "core::bool"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_my_community",
    "type": "function",
    "inputs": [
      {
        "name": "addrs",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [
      {
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_my_recommend",
    "type": "function",
    "inputs": [
      {
        "name": "addrs",
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "outputs": [
      {
        "type": "core::starknet::contract_address::ContractAddress"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "get_total_ns",
    "type": "function",
    "inputs": [],
    "outputs": [
      {
        "type": "core::integer::u256"
      }
    ],
    "stateMutability": "view"
  },
  {
    "name": "RegisterInfo",
    "type": "event",
    "inputs": [
      {
        "name": "from_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "domain",
        "type": "core::felt252"
      },
      {
        "name": "year",
        "type": "core::integer::u64"
      },
      {
        "name": "registerDate",
        "type": "core::integer::u64"
      }
    ]
  },
  {
    "name": "InviteInfo",
    "type": "event",
    "inputs": [
      {
        "name": "original_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "invite_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "time",
        "type": "core::integer::u64"
      }
    ]
  },
  {
    "name": "CommunityInfo",
    "type": "event",
    "inputs": [
      {
        "name": "community_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "member_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "time",
        "type": "core::integer::u64"
      }
    ]
  },
  {
    "name": "InviteEarnInfo",
    "type": "event",
    "inputs": [
      {
        "name": "from_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "earn_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "kind",
        "type": "core::integer::u8"
      },
      {
        "name": "amount",
        "type": "core::integer::u256"
      },
      {
        "name": "time",
        "type": "core::integer::u64"
      }
    ]
  },
  {
    "name": "CommunityEarnInfo",
    "type": "event",
    "inputs": [
      {
        "name": "from_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "earn_address",
        "type": "core::starknet::contract_address::ContractAddress"
      },
      {
        "name": "kind",
        "type": "core::integer::u8"
      },
      {
        "name": "amount",
        "type": "core::integer::u256"
      },
      {
        "name": "time",
        "type": "core::integer::u64"
      }
    ]
  }
]

export default nsAbi