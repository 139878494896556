import * as ethers from "ethers";
import nsAbi from "../json/polygonzkevm_ns_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    
    providers = new ethers.providers.JsonRpcProvider('https://rpc-2.bevm.io')
    
    testContractAddress = "0xa8CcCb1bE30604e69d5014022B56Aed8B247bfb0"
    // testerc20Address = "0x3971797b7178d0f782e0b5477d53af922ebedfea"
    // teststakecontractAddress = "0xb2B8578c1D7BC82875721A5346CcE565Bc4Aa455"

    // provider = new ethers.providers.Web3Provider(
    //     window.ethereum,
    // )
    // signer = this.provider.getSigner()

    chainID = 1501

    BevmContractInit = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    BevmContract = new ethers.Contract(this.testContractAddress, nsAbi, this.providers)
    
    constructor(){
        makeAutoObservable(this)
    }
    reset() {
        this.provider = new ethers.providers.Web3Provider(
            window.ethereum,
        )
        this.signer = this.provider.getSigner()
        this.BevmContract = new ethers.Contract(this.testContractAddress, nsAbi, this.signer)
      }

}
const BevmWallet = new Wallet()

export {BevmWallet}