const stakeAbi = [
    {
      "name": "constructor",
      "type": "function",
      "inputs": [
        {
          "name": "_owner",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "set_token_address",
      "type": "function",
      "inputs": [
        {
          "name": "_ngttokenaddress",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "make_order",
      "type": "function",
      "inputs": [
        {
          "name": "amount",
          "type": "core::integer::u256"
        },
        {
          "name": "kinds",
          "type": "core::integer::u8"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "claim_order",
      "type": "function",
      "inputs": [
        {
          "name": "_index",
          "type": "core::integer::u64"
        }
      ],
      "outputs": [],
      "stateMutability": "external"
    },
    {
      "name": "get_order_index",
      "type": "function",
      "inputs": [
        {
          "name": "_address",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [
        {
          "type": "core::integer::u64"
        }
      ],
      "stateMutability": "view"
    },
    {
      "name": "pledge::pledge::OrderInfo",
      "type": "struct",
      "members": [
        {
          "name": "orderindex",
          "type": "core::integer::u64"
        },
        {
          "name": "seedmoney",
          "type": "core::integer::u256"
        },
        {
          "name": "kinds",
          "type": "core::integer::u8"
        },
        {
          "name": "starttime",
          "type": "core::integer::u64"
        }
      ]
    },
    {
      "name": "get_order_info",
      "type": "function",
      "inputs": [
        {
          "name": "_address",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ],
      "outputs": [
        {
          "type": "core::array::Array::<pledge::pledge::OrderInfo>"
        }
      ],
      "stateMutability": "view"
    }
  ]

  export default stakeAbi