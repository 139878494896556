import React, { Component, useState, useRef, useContext, useEffect } from 'react'
import '../styles/myinvites.css'
import Maincontext from './context'
import axios from 'axios'

function HandString(str){
  let length = str.length;
  let pre;
  if(str.length == 64){
      pre = str.substr(0,2) + '00' + str.substr(2,1);

  }else{
      pre = str.substr(0,5);
  }
  // let pre = str.substr(0,5);
  let end = str.substr(length - 4, 4);
  return pre+'...'+ end;
}

const ParsTime=(time)=>{
  let timestamp = time
  // 此处时间戳以毫秒为单位
  if(time == 0){
    return 0
  }else{
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let  GMT =  Year + '-' + Moth + '-' + Day;
    return (GMT + ' GMT')
  }
}

function GetInviteInfo(){
      const inviteRef = useRef(); //创建useRef
      const [page, setPage] = useState(1);
      const [totalPage, setTotalPage] = useState(1);
      const [data, setData] = useState([]);
      const myAccount = useContext(Maincontext);

      // const getDatas = () => axios.get('https://zk-ns.xyz/api/getMyInvite?page_num=1&page_size=2&address=0x7d7e66eec14e55a115c08e559acbf21758e1a0c75da705f4ea9f8efc1e861b9')
      const getDatas = async(_pages, _address) =>{
        let chains_num = 1
        if(myAccount.network == 'zkSync Era'){
          chains_num = 2
        }else if(myAccount.network == 'Polygon zkEVM'){
          chains_num = 3
        }else if(myAccount.network == 'Linea'){
          chains_num = 4
        }
        try{
          const res = await axios.get('https://zk-ns.xyz/api/getMyInvite', {
              params: {
                chain_num: chains_num,
                page_num: _pages,
                page_size: 10,
                address: _address
              }
          })
          // console.log(res)
          if(res.status == 200){
            if(res.data.paging.total%res.data.paging.page_size == 0){
              if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                setTotalPage(res.data.paging.total/res.data.paging.page_size)
              }
            }else{
                setTotalPage(res.data.paging.total/res.data.paging.page_size + 1)
            }
            let arr = []
            for(let i = 0; i < res.data.data.length; i++){
              arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].invite_address), time: ParsTime(res.data.data[i].time)})
            }
            setData(data.concat(arr))
           }
        }catch(e){
          console.log(e)
        }
      }

      const handleScroll = () =>{
          let res=inviteRef.current.scrollHeight - inviteRef.current.clientHeight- inviteRef.current.scrollTop;
          if (res>1) {
                //未到底
            } else {
                //已到底部
                // console.log('到底了')
                if(page < totalPage){
                  if(myAccount.address.length > 0){
                    getDatas(page+1, myAccount.address)
                    setPage(page+1)
                  }
                }
          }
        }

        useEffect ( ()=>{
          if(myAccount.address.length > 0){
             getDatas(page, myAccount.address)
            //  console.log(myAccount.address)
          }
        }, [myAccount.address]);
        if(data.length> 0){
            return (
              <div className='myinvites_info_content' ref={inviteRef} onScroll={handleScroll}>
                  {data.map(item => <div className='myinvites_info_content_detail' key={item.id}>
                        <li>{item.name}</li>
                        <li>{item.time}</li>
                  </div>)}
                </div>
            )
        }else{
          return (
            <div className='myinvites_no_info' >
                No Invites Yet
              </div>
          )
        }
}

function GetInviteEarnInfo(){
      const inviteEarnRef = useRef(); //创建useRef
      const [page, setPage] = useState(1);
      const [totalPage, setTotalPage] = useState(1);
      const [data, setData] = useState([]);
      const myAccount = useContext(Maincontext);

      // const getData = (pages) => {
      //     let arr = []
      //     let time = new Date().toLocaleDateString()
      //     for(let i = 0; i < 10; i++){
      //       arr.push({id: (pages - 1)*10 + i, name: '0x23...56wd' + ((pages - 1)*10 + i), amount: 10 + (pages - 1)*10 + i, time: time})
      //     }
      //     return arr
      // }
      const getData = async(_pages, _address) =>{
        let chains_num = 1
        if(myAccount.network == 'zkSync Era'){
          chains_num = 2
        }else if(myAccount.network == 'Polygon zkEVM'){
          chains_num = 3
        }else if(myAccount.network == 'Linea'){
          chains_num = 4
        }
        try{
          const res = await axios.get('https://zk-ns.xyz/api/getMyInviteEarn', {
              params: {
                chain_num: chains_num,
                page_num: _pages,
                page_size: 10,
                address: _address
              }
          })
          if(res.status == 200){
            if(res.data.paging.total%res.data.paging.page_size == 0){
              if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                setTotalPage(res.data.paging.total/res.data.paging.page_size)
              }
            }else{
                setTotalPage(res.data.paging.total/res.data.paging.page_size + 1)
            }
            let arr = []
            for(let i = 0; i < res.data.data.length; i++){
              let kind;
              if(res.data.data[i].kind == 1){
                  kind = 'NGT'
                  arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].from_address), amount: (res.data.data[i].amount/(10**18)).toFixed(1), kind: kind, time: ParsTime(res.data.data[i].time)})
              }else{
                  kind = 'ETH'
                  arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: HandString(res.data.data[i].from_address), amount: (res.data.data[i].amount/(10**18)).toFixed(3), kind: kind, time: ParsTime(res.data.data[i].time)})
              }
              
            }
            setData(data.concat(arr))
           }
        }catch(e){
          console.log(e)
        }
      }

      const handleScroll = () =>{
          let res=inviteEarnRef.current.scrollHeight - inviteEarnRef.current.clientHeight- inviteEarnRef.current.scrollTop;
          if (res>1) {
                //未到底
            } else {
                //已到底部
                // console.log('到底了')
                if(page < totalPage){
                  if(myAccount.address.length>0){
                    getData(page+1, myAccount.address)
                    setPage(page+1)
                  }
                }
          }
        }
        useEffect ( ()=>{
          if(myAccount.address.length>0){
            getData(page, myAccount.address)
          } 
        }, [myAccount.address]);
        if(data.length> 0){
          return (
            <div className='myinvites_info_content' ref={inviteEarnRef} onScroll={handleScroll}>
                {data.map(item => <div className='myinvites_earn_detail' key={item.id}>
                      <li>{item.name}</li>
                      <li>{item.amount}</li>
                      <li>{item.kind}</li>
                      <ul>{item.time}</ul>
                </div>)}
                
              </div>
          )
        }else{
          return (
            <div className='myinvites_no_info' >
                No Invite Earnings Yet
              </div>
          )
        }
        
}

export class MyInvites extends Component {
    state = {
      choose: 'invite'
    }
    handerClick = (data) => {
      this.setState({choose: data})
      // console.log('choose:', data)
    }

    render() {
        return (
            <div>
                <div className='myinvites_info'>
                  <div className='myinvites_info_child'>
                    <p>My Invites</p>
                  </div>
                </div> 
                <div className='myinvites_info'>
                  <div className='myinvites_info_title'>
                    <div className='title_wrap' onClick={() =>this.handerClick('invite')}>
                      <li className= {this.state.choose === 'invite' ? "chose" : ""}>Invites</li>
                      </div>
                    <div className='title_wrap' onClick={() =>this.handerClick('earn')}>
                      <li className={this.state.choose === 'earn' ? "chose" : ""} >Invite Earnings</li> 
                      </div>
                  </div>
                </div>
                <div className='myinvites_info'>
                     {this.state.choose === 'invite' ? <GetInviteInfo /> : <GetInviteEarnInfo />}
                </div>
            </div>
        
        )
      }
}

export default MyInvites