import * as ethers from "ethers";
import erc20Abi from "../json/polygonzkevm_erc20_json"
import {makeAutoObservable} from 'mobx'

class Wallet{
    
    providers = new ethers.providers.JsonRpcProvider('https://celo.api.onfinality.io/public')
    
    //testnet
    testerc20Address = "0xa8CcCb1bE30604e69d5014022B56Aed8B247bfb0"

    // provider = new ethers.providers.Web3Provider(
    //     window.ethereum,
    // )
    // signer = this.provider.getSigner()

    chainID = 125

    CeloErc20ContractInit = new ethers.Contract(this.testerc20Address, erc20Abi, this.providers)
    CeloErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.providers)
    
    constructor(){
        makeAutoObservable(this)
    }
    reset() {
        this.provider = new ethers.providers.Web3Provider(
            window.ethereum,
        )
        this.signer = this.provider.getSigner()
        this.CeloErc20Contract = new ethers.Contract(this.testerc20Address, erc20Abi, this.signer)
      }

}
const CeloWallet = new Wallet()

export {CeloWallet}