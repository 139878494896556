import React, { useState, useContext, useEffect } from 'react'
import { Contract, Account, ec, json, num, encode, SequencerProvider, CallData, shortString, uint256} from "starknet";
import '../styles/claim_token.css'
import copy_logo from '../icons/copy1.png'
import loading from '../icons/loading.gif'
import MyInvites from './myinvites'
import Be_leader from './beleader'
import { StarknetWallet } from '../store/starknet'
import {ZKsyncWallet } from '../store/zksync';
import {PolygonZKevmWallet } from '../store/polygonzkevm';
import {LineaWallet } from '../store/linea';
import Maincontext from './context'
import copy from  'copy-to-clipboard';

const Claim_token = () => {

    const myAccount = useContext(Maincontext);
    const [url, setUrl] = useState('https://zk-ns.xyz');
    const [amount, setAmount] = useState(0);
    const [claimStatus, setClaimStatus] = useState(0);
    const [recommendAddress, setRecommendAddress] = useState('');
    const [effectAddress, setEffectAddress] = useState(false);
    const [hasLeader, setHasLeader] = useState(false);

    const getReward = async()=>{

        if(myAccount.network == 'Starknet'){
            if(myAccount.address.length > 0){
                try{
                    const res1 = await StarknetWallet.StarknetContract.get_reward(myAccount.address);
                    setAmount(res1.toString()/(10**18));
                    const res2 = await StarknetWallet.StarknetContract.check_recommend_effect(myAccount.address);
                    // console.log(res2);
                    if(res2){
                        setEffectAddress(true)
                        setUrl('https://zk-ns.xyz?'+ myAccount.address.substr(0,5) +'...'+ myAccount.address.substr(myAccount.address.length - 4, 4))
                    }
                    const res3 = await StarknetWallet.StarknetContract.get_my_community(myAccount.address);
                    // console.log(res3)
                    const mycommunity = num.toHex(res3);
                    // console.log(encode.sanitizeHex(mycommunity));
                    // console.log(myAccount.address);
                    // console.log(encode.sanitizeHex(mycommunity) == myAccount.address);
                    if(encode.sanitizeHex(mycommunity) == myAccount.address){
                        setHasLeader(true);
                    }
                }catch(e){
                    console.log(e)
        
                }
            }
        }else if(myAccount.network == 'zkSync Era'){
            if(myAccount.address.length > 0){
                try{
                    const res1 = await ZKsyncWallet.ZKsyncContract.getmyReward(myAccount.address);
                    setAmount(res1.toString()/(10**18));
                    const res2 = await ZKsyncWallet.ZKsyncContract.checkrecommendEffect(myAccount.address);
                    // console.log(res2);
                    if(res2){
                        setEffectAddress(true)
                        setUrl('https://zk-ns.xyz?'+ myAccount.address.substr(0,5) +'...'+ myAccount.address.substr(myAccount.address.length - 4, 4))
                    }
                    const res3 = await ZKsyncWallet.ZKsyncContract.checkCommunity(myAccount.address);
                    if(res3){
                        setHasLeader(true);
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }else if(myAccount.network == 'Polygon zkEVM'){
            if(myAccount.address.length > 0){
                try{
                    const res1 = await PolygonZKevmWallet.PolygonZKevmContract.getmyReward(myAccount.address);
                    setAmount(res1.toString()/(10**18));
                    const res2 = await PolygonZKevmWallet.PolygonZKevmContract.checkrecommendEffect(myAccount.address);
                    // console.log(res2);
                    if(res2){
                        setEffectAddress(true)
                        setUrl('https://zk-ns.xyz?'+ myAccount.address.substr(0,5) +'...'+ myAccount.address.substr(myAccount.address.length - 4, 4))
                    }
                    const res3 = await PolygonZKevmWallet.PolygonZKevmContract.checkCommunity(myAccount.address);
                    if(res3){
                        setHasLeader(true);
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }else if(myAccount.network == 'Linea'){
            if(myAccount.address.length > 0){
                try{
                    const res1 = await LineaWallet.LineaContract.getmyReward(myAccount.address);
                    setAmount(res1.toString()/(10**18));
                    const res2 = await LineaWallet.LineaContract.checkrecommendEffect(myAccount.address);
                    // console.log(res2);
                    if(res2){
                        setEffectAddress(true)
                        setUrl('https://zk-ns.xyz?'+ myAccount.address.substr(0,5) +'...'+ myAccount.address.substr(myAccount.address.length - 4, 4))
                    }
                    const res3 = await LineaWallet.LineaContract.checkCommunity(myAccount.address);
                    if(res3){
                        setHasLeader(true);
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }
    
      
    }
    const getPageQuery = () => window.location.href.split('?')[1];



    useEffect ( ()=>{
        setUrl('https://zk-ns.xyz')
        setEffectAddress(false)
        setHasLeader(false)
        getReward()
        let invites = getPageQuery()
        // console.log(myAccount.network)
        if(myAccount.network == 'Starknet'){
            if(invites && invites.length ==66){
                setRecommendAddress(invites)
            }else{
                setRecommendAddress('0x07D7e66EEc14e55A115C08E559ACbf21758E1A0C75DA705F4eA9f8efc1e861b9')
            }
        }else if(myAccount.network == 'zkSync Era'){
            if(invites && invites.length ==42){
                setRecommendAddress(invites)
            }else{
                setRecommendAddress('0x30304ec65dbc9B7Ff4953430D55Aed94A6fb6E70')
            }
        }else if(myAccount.network == 'Polygon zkEVM'){
            if(invites && invites.length ==42){
                setRecommendAddress(invites)
            }else{
                setRecommendAddress('0x30304ec65dbc9B7Ff4953430D55Aed94A6fb6E70')
            }
        }else if(myAccount.network == 'Linea'){
            if(invites && invites.length ==42){
                setRecommendAddress(invites)
            }else{
                setRecommendAddress('0x30304ec65dbc9B7Ff4953430D55Aed94A6fb6E70')
            }
        }
        

      }, [myAccount.network, myAccount.address]);
    
    const claimToken = async() => {
        if(myAccount.network == 'Starknet'){
            if(myAccount.address.length > 0 && amount>0){
                try{
                    const res1 = await StarknetWallet.StarknetContract.check_recommend_effect(recommendAddress);
                    // console.log(res1)
                    if(res1){
                        const claimTokenCall = await myAccount.account.execute(
                            [
                            // Calling the first contract
                            {
                            contractAddress: StarknetWallet.testContractAddress,
                            entrypoint: "claim_ngttoken",
                            // approve 1 wei for bridge
                            calldata: CallData.compile({
                                recommend: recommendAddress,
                            })
                            }
                        ]
                        )
                        setClaimStatus(1);
                        await StarknetWallet.provider.waitForTransaction(claimTokenCall.transaction_hash);
                        setClaimStatus(0);
                        setAmount(0);
                    }else{
                        alert('Recommend Address Invalid')
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }else if(myAccount.network == 'zkSync Era'){
            if(myAccount.address.length > 0 && amount>0){
                try{
                    const res1 = await ZKsyncWallet.ZKsyncContract.checkrecommendEffect(recommendAddress);
                    // console.log(recommendAddress)
                    if(res1){
                        setClaimStatus(1);
                        const tx = await ZKsyncWallet.ZKsyncContract.userclaimToken(recommendAddress);
                        await tx.wait();
                        setClaimStatus(0);
                        setAmount(0);
                    }else{
                        alert('Recommend Address Invalid')
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }else if(myAccount.network == 'Polygon zkEVM'){
            if(myAccount.address.length > 0 && amount>0){
                try{
                    const res1 = await PolygonZKevmWallet.PolygonZKevmContract.checkrecommendEffect(recommendAddress);
                    if(res1){
                        setClaimStatus(1);
                        const tx = await PolygonZKevmWallet.PolygonZKevmContract.userclaimToken(recommendAddress);
                        await tx.wait();
                        setClaimStatus(0);
                        setAmount(0);
                    }else{
                        alert('Recommend Address Invalid')
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }else if(myAccount.network == 'Linea'){
            if(myAccount.address.length > 0 && amount>0){
                try{
                    const res1 = await LineaWallet.LineaContract.checkrecommendEffect(recommendAddress);
                    if(res1){
                        setClaimStatus(1);
                        const tx = await LineaWallet.LineaContract.userclaimToken(recommendAddress);
                        await tx.wait();
                        setClaimStatus(0);
                        setAmount(0);
                    }else{
                        alert('Recommend Address Invalid')
                    }
                }catch(e){
                    console.log(e)
                }
            }
        }

    }
    const handCopy = () =>{
        if(effectAddress){
            copy('https://zk-ns.xyz?' + myAccount.address);
            alert("copy success");
        }
        
    }
  
    return (
        <div className='claim_token_parent'>
            <div className='claim_token'>
                <div className='claim_token_child'>
                    <div className='left'>
                        <li>Amounts:</li>
                        </div>
                    <div className='mid'>
                        <li>{amount} NGT</li>
                        </div>
                    <div className='right'>
                        {claimStatus == 0 ? <div className='claim_button' onClick={claimToken}>Claim</div> : <div className='claim_button'><img src={loading}/></div>}
                    </div>
                </div>
            </div> 
            <div className='invites'>
                <div className='invites_child'>
                    <div className='left'>
                        <li>My Inviting Link:</li>
                    </div>
                    <div className='mid'>
                        <li>{url}</li>
                        </div>
                    <div className='right'>
                        <img src={copy_logo} onClick={handCopy}></img>
                        </div>
                </div>
            </div> 
            <MyInvites />
            <Be_leader hasLeader={hasLeader} effectAddress = {effectAddress} setHasLeader ={setHasLeader}/>
      </div>
    )
}

export default Claim_token