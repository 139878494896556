const mintAbi = [
    {
      "name": "Free_Mint",
      "type": "impl",
      "interface_name": "zkchick::free_mint::free_mint::IFree_Mint"
    },
    {
      "name": "core::integer::u256",
      "type": "struct",
      "members": [
        {
          "name": "low",
          "type": "core::integer::u128"
        },
        {
          "name": "high",
          "type": "core::integer::u128"
        }
      ]
    },
    {
      "name": "core::bool",
      "type": "enum",
      "variants": [
        {
          "name": "False",
          "type": "()"
        },
        {
          "name": "True",
          "type": "()"
        }
      ]
    },
    {
      "name": "zkchick::free_mint::free_mint::MintInfo",
      "type": "struct",
      "members": [
        {
          "name": "chick_mint_num",
          "type": "core::integer::u256"
        },
        {
          "name": "egg_mint_num",
          "type": "core::integer::u256"
        },
        {
          "name": "chick_mint_total",
          "type": "core::integer::u256"
        },
        {
          "name": "egg_mint_total",
          "type": "core::integer::u256"
        },
        {
          "name": "my_mint_chick",
          "type": "core::bool"
        },
        {
          "name": "start_mint",
          "type": "core::bool"
        },
        {
          "name": "my_mint_egg",
          "type": "core::integer::u256"
        },
        {
          "name": "max_egg_mint_each",
          "type": "core::integer::u256"
        }
      ]
    },
    {
      "name": "zkchick::free_mint::free_mint::IFree_Mint",
      "type": "interface",
      "items": [
        {
          "name": "free_mint_chick",
          "type": "function",
          "inputs": [],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "free_mint_egg",
          "type": "function",
          "inputs": [
            {
              "name": "amount",
              "type": "core::integer::u256"
            },
            {
              "name": "version",
              "type": "core::integer::u8"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_eth20_address",
          "type": "function",
          "inputs": [
            {
              "name": "_ethaddress",
              "type": "core::starknet::contract_address::ContractAddress"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_egg_mint_price",
          "type": "function",
          "inputs": [
            {
              "name": "_price",
              "type": "core::integer::u256"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_nft_address",
          "type": "function",
          "inputs": [
            {
              "name": "_chick_nft_address",
              "type": "core::starknet::contract_address::ContractAddress"
            },
            {
              "name": "_egg_nft_address",
              "type": "core::starknet::contract_address::ContractAddress"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_name_address",
          "type": "function",
          "inputs": [
            {
              "name": "_name_address",
              "type": "core::starknet::contract_address::ContractAddress"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_max_egg_mint_each",
          "type": "function",
          "inputs": [
            {
              "name": "amount",
              "type": "core::integer::u256"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_chick_egg_total",
          "type": "function",
          "inputs": [
            {
              "name": "_chick_total",
              "type": "core::integer::u256"
            },
            {
              "name": "_egg_total",
              "type": "core::integer::u256"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "set_start_mint",
          "type": "function",
          "inputs": [
            {
              "name": "_start",
              "type": "core::bool"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "get_mint_info",
          "type": "function",
          "inputs": [
            {
              "name": "contract_address",
              "type": "core::starknet::contract_address::ContractAddress"
            }
          ],
          "outputs": [
            {
              "type": "zkchick::free_mint::free_mint::MintInfo"
            }
          ],
          "state_mutability": "view"
        },
        {
          "name": "get_owner",
          "type": "function",
          "inputs": [],
          "outputs": [
            {
              "type": "core::starknet::contract_address::ContractAddress"
            }
          ],
          "state_mutability": "view"
        },
        {
          "name": "claim",
          "type": "function",
          "inputs": [
            {
              "name": "_amount",
              "type": "core::integer::u256"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        },
        {
          "name": "upgrade",
          "type": "function",
          "inputs": [
            {
              "name": "new_class_hash",
              "type": "core::starknet::class_hash::ClassHash"
            }
          ],
          "outputs": [],
          "state_mutability": "external"
        }
      ]
    },
    {
      "name": "constructor",
      "type": "constructor",
      "inputs": [
        {
          "name": "_owner",
          "type": "core::starknet::contract_address::ContractAddress"
        }
      ]
    },
    {
      "name": "reset_for_test",
      "type": "function",
      "inputs": [],
      "outputs": [],
      "state_mutability": "external"
    },
    {
      "kind": "struct",
      "name": "zkchick::free_mint::free_mint::Free_Mint::Upgraded",
      "type": "event",
      "members": [
        {
          "kind": "data",
          "name": "class_hash",
          "type": "core::starknet::class_hash::ClassHash"
        }
      ]
    },
    {
      "kind": "enum",
      "name": "zkchick::free_mint::free_mint::Free_Mint::Event",
      "type": "event",
      "variants": [
        {
          "kind": "nested",
          "name": "Upgraded",
          "type": "zkchick::free_mint::free_mint::Free_Mint::Upgraded"
        }
      ]
    }
  ]

export default mintAbi