import React, { Component } from 'react'
import '../styles/mydomaininfo.css'

const Mydomaininfo = ({ns, time})=> {
    const ParsTime=()=>{
        let timestamp = time
        // 此处时间戳以毫秒为单位
        let date = new Date(parseInt(timestamp) * 1000);
        let Year = date.getFullYear();
        let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
        let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        let  GMT =  Year + '-' + Moth + '-' + Day + '   '+ Hour +':'+ Minute  + ':' + Sechond;
        
        // console.log(GMT)  // 2022-09-07 15:56:07
        // return GMT;
        return (<li>Expires: {GMT} GMT</li>)

    }
    return(
        // let name = 'william.stark'
        // let time = new Date().toLocaleDateString()
            <div>
                <ul className='mydomain_info'>
                  <ul className='mydomain_info_child'>
                    <p>My Domains</p>
                  </ul>
                </ul> 
                <ul className='mydomain_info_detail'>
                     <ul className='mydomain_info_detail_child'>
                        <li>{ns}</li>
                        {/* <li>Expires: {parsTime}</li> */}
                        <ParsTime />
                      </ul>
                </ul>
            </div>
        
        )
    }

export default Mydomaininfo