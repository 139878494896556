// import {BrowserRouter, Routes, Route} from "react-router-dom"
// import Mydomain from "./component/mydomain"
// import Community from "./component/community"
import Header from "./component/header";
import { useState } from "react";
import "./App.css";
import {BrowserRouter, Link, Routes, Route} from "react-router-dom";



function App() {
      const [accounts, setAccounts] = useState([]);
      return(
        // <div><Header accounts={accounts} setAccounts={setAccounts} /></div>
        <BrowserRouter>
          <Link to="/"></Link>
          <Link to="/community"></Link>
          <Link to="/game"></Link>
          <Link to="/bridge"></Link>
          <Link to="/bevm"></Link>
          <Routes>
            <Route path="/" element={<Header chooses='domain' chain='Starknet' accounts={accounts} setAccounts={setAccounts} />}></Route>
            <Route path="/bevm" element={<Header chooses='domain' chain='Bevm' accounts={accounts} setAccounts={setAccounts} />}></Route>
            <Route path="/community" element={<Header chooses='community' chain='Starknet' accounts={accounts} setAccounts={setAccounts} />}></Route>
            <Route path="/game" element={<Header chooses='game' chain='Starknet' accounts={accounts} setAccounts={setAccounts} />}></Route>
            <Route path="/bridge" element={<Header chooses='bridge' chain='Starknet' accounts={accounts} setAccounts={setAccounts} />}></Route>
          </Routes>
      </BrowserRouter>
      )
 }


export default App;
